import { message } from "antd";
import { closingConstants } from "./action-types";
import { contractStoreHandle } from "../../ContractStore/state/actions";
const { closingApi } = require("../utils/api");

const transactionEmailSuccess=(response)=>{
 return {
  type:closingConstants.TRANSACTION_EMAIL_SUCCESS,
  transactionEmail: response.data.info
 }

}
const transactionEmailError=(error)=>{
 return {
  type:closingConstants.TRANSACTION_EMAIL_FAIL,
  error:error
 }

}
const transactionReportEmail=(offerId, accessTokan)=>{
  return(dispatch)=>{
    closingApi.transactionemail(offerId, accessTokan)
    .then((response)=>{
     dispatch(transactionEmailSuccess(response))
    }).catch((error) => {
      dispatch(transactionEmailError(error));
    });
  }
}

// <----------TITLECOMPANY-------------->
const getTitleCompanyLoading = () => {
  return {
    type: closingConstants.GET_TITLE_COMPANY_LOADING,
  };
};
const getTitleCompanyError = () => {
  return {
    type: closingConstants.GET_TITLE_COMPANY_ERROR,
  };
};
const getTitleCompanySuccess=(res)=>{

  return {
    type:closingConstants.GET_TITLE_COMPANY_SUCCESS,
    titleCompanydata:res.data.info
  }
}
const getTitleCompany=(contractauthkey)=>{
  return(dispatch) => {
    dispatch(getTitleCompanyLoading())
    closingApi.gettitlecompany(contractauthkey)
    .then((response) => {
      dispatch(getTitleCompanySuccess(response))

    })
    .catch((err) => {
      dispatch(getTitleCompanyError())
    })
  }
}

const getTitleOfficeLoading = () => {
  return {
    type: closingConstants.GET_TITLE_OFFICE_LOADING,
  };
};
const getTitleOfficeError = () => {
  return {
    type: closingConstants.GET_TITLE_OFFICE_ERROR,
  };
};
const getTitleOfficeSuccess=(res)=>{

  return {
    type:closingConstants.GET_TITLE_OFFICE_SUCCESS,

    titleOfficedata:res.data.info
  }
}
const getTitleOffice=(id,contractAuthKey)=>{
  return(dispatch) => {
    dispatch(getTitleOfficeLoading())
    closingApi.getTitleoffice(id,contractAuthKey)
    .then((response) => {
      dispatch(getTitleOfficeSuccess(response))

    })
    .catch((err) => {
      dispatch(getTitleOfficeError())
    })
  }
}

const getTitleCompanyDataLoading = () => {
  return {
    type: closingConstants.GET_TITLE_COMPANY_DATA_LOADING,
  };
};
const getTitleCompanyDataError = () => {
  return {
    type: closingConstants.GET_TITLE_COMPANY_DATA_ERROR,
  };
};
const getTitleCompanyDataSuccess=(res)=>{
 return {
    type:closingConstants.GET_TITLE_COMPANY_DATA_SUCCESS,
    titleCompanyselecteddata:res.data.info.transactionData

  }
}
const getTitleCompanyData=(id)=>{
  return(dispatch) => {
    dispatch(getTitleCompanyDataLoading())
    closingApi.getTitleCompanyData(id)
    .then((response) => {
      dispatch(getTitleCompanyDataSuccess(response))
})
    .catch((err) => {
      dispatch(getTitleCompanyDataError())
    })
  }
}
const searchTitleOffice=(id,val,contractauthkey)=>{
  return(dispatch) => {
    dispatch(getTitleOfficeLoading())
    closingApi.searchTitleoffice(id,val,contractauthkey)
    .then((response) => {
      dispatch(getTitleOfficeSuccess(response))

    })
    .catch((err) => {
      dispatch(getTitleOfficeError())
    })
  }
}
const addTitleOfficeLoading = () => {
  return {
    type: closingConstants.ADD_TITLE_OFFICE_LOADING,
  };
};
const addTitleOfficeError = () => {
  return {
    type: closingConstants.ADD_TITLE_OFFICE_ERROR,
  };
};
const addTitleOfficeSuccess=(res)=>{

  return {
    type:closingConstants.ADD_TITLE_OFFICE_SUCCESS,
    Officedata:res.data.info
  }
}
const addTitleOffice = (el, contractAuthKey, rootDocData) => {
  return (dispatch, getState) => {
    dispatch(addTitleOfficeLoading());
    let currentUrlId = getState().createOffer.currentUrlId;
    if (el.isFileUpload && el.isFileUpload === true) {
      closingApi.editOfficeImage({ file: el?.file }, contractAuthKey)
        .then((additionalResponse) => {
          el.imageUrl = additionalResponse?.data?.info?.fileData?.fileUrl;
          closingApi.addTitleoffice(el, contractAuthKey, rootDocData)
            .then((response) => {
              dispatch(addTitleOfficeSuccess(response));
              localStorage.setItem(currentUrlId, JSON.stringify(response?.data?.info?.transactionData));
              message.success("Title Company image added successfully!");
              dispatch(contractStoreHandle.autoSaveContract(currentUrlId, contractAuthKey, null, { SelectedBy: rootDocData?.openFor }));
              dispatch(getTitleCompanyData(el?.offerId));
            })
            .catch((err) => {
              dispatch(addTitleOfficeError());
            });
        })
        .catch((err) => {
          dispatch(addTitleOfficeError());
        });
    } else {
      // If 'isFileUpload' key is not present or is not true
      // closingApi.addTitleoffice(el, contractAuthKey, rootDocData)
        // .then((response) => {
          // dispatch(addTitleOfficeSuccess(response));
          // localStorage.setItem(currentUrlId, JSON.stringify(response?.data?.info?.transactionData));
          el.SelectedBy= rootDocData?.openFor
          dispatch(contractStoreHandle.autoSaveContract(currentUrlId, contractAuthKey, null, el));
          message.success("Title Company added successfully!");
          // dispatch(getTitleCompanyData(el?.offerId));
        // })
        // .catch((err) => {
        //   dispatch(addTitleOfficeError());
        // });
    }
  };
};


const titleCompanyModalState = (payload)=>{
  return {
    type: closingConstants.SET_TITLE_COMPANY_MODAL_STATE,
    payload,
    // openTitleCompanyModal: payload.openTitleCompanyModal,
    // selectOffice: payload.selectOffice,
    // companyModalStep: payload.companyModalStep,
    // titleOffice: payload.titleOffice,
    // titleCloser: payload.titleCloser,
    // titleRep: payload.titleRep,
  }
}
const getSharedDocsLoading = () => {
  return {
    type: closingConstants.GET_SHARED_DOCS_LOADING,
  };
};
const getSharedDocsError = () => {
  return {
    type: closingConstants.GET_SHARED_DOCS_ERROR,
  };
};
const getSharedDocsSuccess = (response) => {
  return {
    type: closingConstants.GET_SHARED_DOCS_FOR_TR,
    sharedDocs: response?.data?.info,
  }
}

const getSharedDocs = (offerId, accessToken) => {
  return (dispatch, getState) => {
    dispatch(getSharedDocsLoading())
    closingApi.getSharedDocsRequest(offerId, accessToken)
    .then((response)=>{
      dispatch(getSharedDocsSuccess(response));
    }).catch((error) => {
      dispatch(getSharedDocsError())
      message.error(error?.response?.data?.message || 'We encountered an issue while fetching data from shared documents.Please try again later!');
    })
  }
}

export const closingActions = {
  transactionReportEmail,
  addTitleOffice,
  searchTitleOffice,
  getTitleOffice,
  getTitleCompany,
  titleCompanyModalState,
  getSharedDocs,
};
