import React from "react";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { useDispatch } from "react-redux";
import { Button, Input, Modal, Form } from "antd";

const CreateTemplateModal = ({ urlId, contractAuthKey, modalState, setModalState }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const saveModalCancel = () => {
    setModalState((prevState) => ({ ...prevState, createTemplateModal: false }));
    form?.resetFields();
  };

  let onFinish = (values = {}) => {
    dispatch(contractStoreHandle.autoSaveContract(urlId, contractAuthKey, null, {}, { createTemplate: true, templateName: values?.templateName, templateDescription: values?.templateDescription }));
    saveModalCancel();
  };
  return (
    <Modal title={<div style={{ color: "#2697FF", textDecorationLine: "underline", fontSize: "20px", textAlign: "center" }}>TEMPLATE DETAILS</div>} visible={modalState?.createTemplateModal} onCancel={saveModalCancel} footer={false}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="templateName"
          label={<div style={{ fontSize: "16px", fontWeight: "500", color: "#8e959a" }}> Title </div>}
          rules={[
            {
              required: true,
              message: "Please add title!",
            },
          ]}
        >
          <Input placeholder="Enter Title" allowClear autoFocus  />
        </Form.Item>
        <Form.Item
          name="templateDescription"
          label={<div style={{ fontSize: "16px", fontWeight: "500", color: "#8e959a" }}> Description </div>}
          rules={[
            {
              required: true,
              message: "Please add description!",
            },
          ]}
        >
          <Input placeholder="Enter Description" allowClear  />
        </Form.Item>
        <Form.Item style={{textAlign:"center", marginTop:"15px"}}>
          <Button onClick={saveModalCancel} style={{ marginRight: "40px" }}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTemplateModal;
