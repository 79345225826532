import { checkIfDisabled } from "../../Common/commondata/commonData";
import { offerTableConstants } from "./action-types";

const initialState = {
  offerTableErrorData: null,
  offerTableData: null,
  totalOffers:0,
  propertySellers:[],
  isAccpetedOfferPresent: false,
  alreadyPresentCriteria: false,
  offerTableloading: true,
  offerTableCustomLoading:false,
  postStarsData: null,
  postStarsloading: true,
  postStarsErrorData: null,

  URLShortData: null,
  URLShortLoading: true,
  URLShortErrorData: null,

  selectOfferData: null,
  selectOfferLoading: true,
  selectOfferErrorData: null,

  checkedOfferErrorData: null,
  checkedOfferloading: true,
  checkedOfferData: null,

  URLofferTableErrorData: null,
  URLofferTableLoading: true,
  offerTablePropertyId: null,
  offerTableAuthKey: null,
  URLofferTableJSON: null,

  inputFillErrorData: null,
  inputFillloading: true,
  inputFillData: null,
  error: false,

  sendEmailLoading: false,
  sendEmailData: null,
  sendEmailError: false,

  sendPasswordLoading: false,
  sendPasswordData: null,
  sendPasswordError: false,

  shareOfferTableLoading: false,
  shareOfferTableData: null,
  shareOfferTableError: false,

  restrictedClientLoading: false,
  restrictedClientData: [],
  restrictedClientError: false,

  restrictOfferTableLoading: false,
  restrictOfferTableData: [],
  restrictOfferTableError: false,

  getConstantsLoading: false,
  OTAddCriteriaKeysArray: null,
  getConstantsError: false,

  getEmail_TemplateData: [],
  getEmail_TemplateLoading: false,
  getEmail_TemplatesError: false,
  checkForDisable: {}
};

const offerTable = (state = initialState, action) => {
  switch (action.type) {
    // URL shorten conversion
    case offerTableConstants.OFFERTABLE_DADA_SUCCESS:
      return {
        ...state,
        URLofferTableLoading: action.URLofferTableLoading,
        offerTableAuthKey: action.offerTableAuthKey,
        offerTablePropertyId: action.offerTablePropertyId,
        URLofferTableJSON: action.URLofferTableJSON,
      };
    case offerTableConstants.OFFERTABLE_DADA_FAILURE:
      return {
        ...state,
        URLofferTableErrorData: action.URLofferTableErrorData,
        URLofferTableLoading: action.URLofferTableLoading,
        offerTableCustomLoading:false
      };
    case offerTableConstants.OFFER_TABLE_SUCCESS:
     const isDisabled = checkIfDisabled({openFor: "SELLER", actualPropertyStatus: action?.offerTableData?.propertyStatus});
     return {
        ...state,
        offerTableErrorData: false,
       offerTableData: action.offerTableData.arrayToReturn,
       OTDefaultKeysArray:action.offerTableData.OTDefaultKeysArray,     
        totalOffers:action.offerTableData.totalOffers,
        isAccpetedOfferPresent: action.offerTableData.isAccpetedOfferPresent,
        alreadyPresentCriteria: action.offerTableData.alreadyPresentCriteria,
        propertySellers: action.offerTableData.propertySellers,
        offerTableloading: false,
        offerTableCustomLoading:false,
        checkForDisable: isDisabled
      };
    case offerTableConstants.OFFER_TABLE_FAILURE:
      return {
        ...state,
        offerTableErrorData: action.offerTableErrorData,
        offerTableloading: false,
        offerTableCustomLoading:false
      };

    case offerTableConstants.POST_STARS_SUCCESS:
      return {
        ...state,
        postStarsData: action.postStarsData,
        postStarsloading: action.postStarsloading,
      };
    case offerTableConstants.POST_STARS_FAILURE:
      return {
        ...state,
        postStarsErrorData: action.postStarsErrorData,
        postStarsloading: action.postStarsloading,
        offerTableCustomLoading:false
      };
    // select offer usr shortening
    case offerTableConstants.URL_SHORT_SELECT_OFFER_FAILURE:
      return {
        ...state,
        URLShortErrorData: action.URLShortErrorData,
        URLShortLoading: action.URLShortLoading,
        offerTableCustomLoading:false
      };
    case offerTableConstants.URL_SHORT_SELECT_OFFER_SUCCESS:
      return {
        ...state,
        URLShortData: action.URLShortData,
        URLShortLoading: action.URLShortLoading,
      };
    // select offer
    case offerTableConstants.SELECT_OFFER_FAILURE:
      return {
        ...state,
        selectOfferErrorData: action.selectOfferErrorData,
        selectOfferLoading: action.selectOfferLoading,
        offerTableCustomLoading:false
      };
    case offerTableConstants.SELECT_OFFER_SUCCESS:
      return {
        ...state,
        selectOfferData: action.selectOfferData,
        selectOfferLoading: action.selectOfferLoading,
      };

    case offerTableConstants.CHECKED_OFFER_SUCCESS:
      return {
        ...state,
        checkedOfferData: action.checkedOfferData,
        checkedOfferloading: action.checkedOfferloading,
      };
    case offerTableConstants.CHECKED_OFFER_FAILURE:
      return {
        ...state,
        checkedOfferErrorData: action.checkedOfferErrorData,
        checkedOfferloading: action.checkedOfferloading,
        offerTableCustomLoading:false
      };

    case offerTableConstants.INPUT_FILL_SUCCESS:
      return {
        ...state,
        checkedOfferData: action.checkedOfferData,
        checkedOfferloading: action.checkedOfferloading,
      };
    case offerTableConstants.INPUT_FILL_FAILURE:
      return {
        ...state,
        inputFillErrorData: action.inputFillErrorData,
        checkedOfferloading: action.checkedOfferloading,
        offerTableCustomLoading:false
      };
    case offerTableConstants.SET_ERROR_IN_OFFER:
      return {
        ...state,
        error: action.error,
      };

    case offerTableConstants.SEND_EMAIL_LOADING:
      return {
        ...state,
        sendEmailLoading: true,
        sendEmailError: false,
      };
    case offerTableConstants.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailData: action.sendEmailData,
        sendEmailLoading: false,
        sendEmailError: false,
      };
    case offerTableConstants.SEND_EMAIL_FAILURE:
      return {
        ...state,
        sendEmailLoading: false,
        sendEmailError: action.sendEmailError,
      };
    case offerTableConstants.SEND_PASSWORD_LOADING:
      return {
        ...state,
        sendPasswordLoading: true,
        sendPasswordError: false,
      };
    case offerTableConstants.SEND_PASSWORD_SUCCESS:
      return {
        ...state,
        sendPasswordData: action.sendPasswordlData,
        sendPasswordLoading: false,
        sendPasswordError: false,
      };
    case offerTableConstants.SEND_PASSWORD_FAILURE:
      return {
        ...state,
        sendPasswordLoading: false,
        sendPasswordError: action.sendPasswordError,
      };
    case offerTableConstants.SHARE_OFFER_TABLE_LOADING:
      return {
        ...state,
        shareOfferTableLoading: true,
        shareOfferTableData: null,
        shareOfferTableError: false,
      };
    case offerTableConstants.SHARE_OFFER_TABLE_SUCCESS:
      return {
        ...state,
        shareOfferTableData: action.shareOfferTableData,
        shareOfferTableLoading: false,
        shareOfferTableError: false,
      };
    case offerTableConstants.SHARE_OFFER_TABLE_FAILURE:
      return {
        ...state,
        shareOfferTableLoading: false,
       shareOfferTableData: null,
       shareOfferTableError: true,
      };
    case offerTableConstants.GET_RESTRICTED_CLIENT_OFFER_TABLE_LOADING:
      return {
        ...state,
        restrictedClientLoading: true,
        restrictedClientData: null,
        restrictedClientError: false,
      };
    case offerTableConstants.GET_RESTRICTED_CLIENT_OFFER_TABLE_SUCCESS:
      return {
        ...state,
        restrictedClientData: action.restrictedClientData,
        restrictedClientLoading: false,
        restrictOfferTableError: false,
      };
    case offerTableConstants.GET_RESTRICTED_CLIENT_OFFER_TABLE_FAILURE:
      return {
        ...state,
        restrictedClientLoading: false,
        restrictedClientData: null,
        restrictedClientError: false,
      };
    case offerTableConstants.GET_CONSTANTS_LOADING:
      return {
        ...state,
        getConstantsLoading: true,
        OTAddCriteriaKeysArray: null,
        getConstantsError: false,
      };
    case offerTableConstants.GET_CONSTANTS_SUCCESS:
      return {
        ...state,
        OTAddCriteriaKeysArray: action.OTAddCriteriaKeysArray,
        getConstantsLoading: false,
        getConstantsError: false,
      };
    case offerTableConstants.GET_CONSTANTS_FAILURE:
      return {
        ...state,
        OTAddCriteriaKeysArray: null,
        getConstantsLoading: false,
        getConstantsError: true,
      };
    case offerTableConstants.RESTRICT_OFFER_TABLE_LOADING:
      return {
        ...state,
        restrictOfferTableLoading: true,
        restrictOfferTableData: null,
        restrictOfferTableError: false,
      };
    case offerTableConstants.RESTRICT_OFFER_TABLE_SUCCESS:
      return {
        ...state,
        restrictOfferTableData: action.restrictOfferTableData,
        restrictOfferTableLoading: false,
        restrictOfferTableError: false,
      };
    case offerTableConstants.RESTRICT_OFFER_TABLE_FAILURE:
      return {
        ...state,
        restrictOfferTableLoading: false,
       restrictOfferTableData: null,
       restrictOfferTableError: true,
      };
      case offerTableConstants.GET_EMAIL_TEMPLATE_LOADING:
        return {
          ...state,
          getEmail_TemplateLoading: true,
          getEmail_TemplatesError: false,
        };
      case offerTableConstants.GET_EMAIL_TEMPLATE_SUCCESS:
        return {
          ...state,
          getEmail_TemplateData: action.getEmail_Templatedata,
          getEmail_TemplateLoading: false,
          getEmail_TemplatesError: false,
        };
      case offerTableConstants.GET_EMAIL_TEMPLATE_FAILURE:
        return {
          ...state,
          getEmail_TemplateLoading: false,
          getEmail_TemplatesError: true,
        };
        case offerTableConstants.SET_OFFER_TABLE_LOADING:
          return {
           ...state,
          offerTableCustomLoading:action.offerTableCustomLoading
        }
      case offerTableConstants.ACCEPT_OFFER_VIA_RESURE_SIGN_LOADING:
        return {
          ...state,
          getEmail_TemplateData: action.getEmail_Templatedata,
          getEmail_TemplateLoading: false,
          getEmail_TemplatesError: false,
        };
      case offerTableConstants.ACCEPT_OFFER_VIA_RESURE_SIGN_SUCCESS:
        return {
          ...state,
          getEmail_TemplateLoading: false,
          getEmail_TemplatesError: true,
        };
        case offerTableConstants.ACCEPT_OFFER_VIA_RESURE_SIGN_FAILURE:
          return {
           ...state,
          offerTableCustomLoading:action.offerTableCustomLoading
        }
  
    default:
      return state;
  }
};
export { offerTable };
