import { documentConstants } from "./action-types";
import { documentDataApi } from "../utils/api";

import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_ADMIN_BASE_URL;

/**
 * To update Navbar title action
 * @param {string}
 */

const setpdfImportState = (payload) => {
  return {
    type: documentConstants.SET_PDF_IMPORT_STATE,
    payload,
  };
};
const pdfError = (value) => {
  return {
    type: documentConstants.PDF_IMPORT_ERROR,
    pdfImportError: value,
  };
};
const urlImportSuccess = (response, urlId) => {
  if (urlId) {
    localStorage.setItem(urlId, JSON.stringify(response.data.info?.transactionData));
  }
  return {
    type: documentConstants.URL_IMPORT_SUCCESS,
    payload: response.data.info?.transactionData,
  };
};

const urlImportError = (error) => {
  return {
    type: documentConstants.URL_IMPORT_ERROR,
    payload: error,
    urlImportErrorAccured: true,
  };
};

const urlImportErrorClear = () => {
  return {
    type: documentConstants.URL_IMPORT_ERROR_CLEAR,
  };
};

const clearUrlData = () => {
  return {
    type: documentConstants.URL_IMPORT_CLEAR_SUCCESS,
    payload: {},
  };
};

const urlImportSuccessImport = (response, clonefromPdf) => {
  if (clonefromPdf) {
    message.success(response?.data?.info?.message || "Offer imported successfully for the selected property.");
    setTimeout(() => {
      window.location.href = window.location.origin + window.location.pathname;
    }, 2000);
  } else {
    let uniqId = uuidv4();
    window.name = uniqId;
    let redirectURL = `${apiURL}/contractDocument/${response?.data?.info?.createUrlDataResult?._id}`;
    window.location.assign(encodeURI(redirectURL));
  }
};

const urlImport = (data, offerTableAuthKey, redirect) => {
  return (dispatch, getState) => {
    let urlId = getState()?.offerTable.URLofferTableJSON._id;
    documentDataApi
      .urlImport(data, offerTableAuthKey)
      .then((response) => {
        if (redirect) {
          urlImportSuccessImport(response, data.clonefromPdf);
        } else {
          dispatch(urlImportSuccess(response, urlId));
        }
      })
      .catch((error) => {
        if (data.clonefromPdf && !(error?.response?.data?.responseType === "OFFER_EXISTS")) {
          dispatch(pdfError(true));
          return;
        }
        console.log("error: ", error);
        message.error(error?.response?.data?.message);
        dispatch(urlImportError(error));
      });
  };
};

export const documentAction = {
  setpdfImportState,
  urlImport,
  clearUrlData,
  urlImportErrorClear,
  pdfError,
};
