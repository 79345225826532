import React, { useState } from "react";
import { Drawer, Input, Spin, Modal, Image, Button, Tooltip } from "antd"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { appStatusChange } from "../state/actions";
import {
  SearchOutlined,
  QuestionCircleOutlined,
  PlayCircleTwoTone,
} from "@ant-design/icons";
import { EmptyData } from "../../Common/components/EmptyData";
import styles from "../styles/Sidebar.module.css"
import "../styles/helpDrawer.css"

const Help = ( {section,offerTableAuthKey} ) => {
  const dispatch = useDispatch();
  const { helpData, helpDataLoading } = useSelector((state) => state.app);
  const [searchVal, setSearchVal] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);
  const [answer, setAnswer] = useState({});
  const [showAns, setShowAns] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [visible, setVisible] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [htmlString, setHtmlString] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const handleSeeMoreBtn = () => {
    window.open("https://resure.realestate/support", "_blank")};
  const handleShowDrawer = () => {
    setShowDrawer(true);
  };
  const onClose = () => {
    setShowDrawer(false);
  };
  const onAnsClose = () => {
    setShowAns(false);
    setVideoId("");
  };
  const handleCancel = () => {
    setShowVideo(false);
  };
  const getVideoId = () => {
    const { videos } = answer;
    for (let key in videos) {
      const parts = videos[key].split("/");
      const videoID = parts[parts.length - 1].split("?")[0];
      setVideoId(videoID);
    }
  };
  window.handleImage = (imageSrc) => {
    setImageSrc(imageSrc);
    setVisible(true);
  };
  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(answer?.content, "text/html");
    const imgElements = doc.querySelectorAll('img');
    imgElements.forEach((img) => {
        const imageSrc = img.getAttribute('src');
      img.setAttribute("onClick", `handleImage('${imageSrc}')`);
    });
    let updatedHtmlString = new XMLSerializer().serializeToString(doc);
    setHtmlString(updatedHtmlString);
    getVideoId();
  }, [showAns]);
  const handleImageCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (searchVal.length > 0) {
      const timeoutId = setTimeout(() => {
        dispatch(appStatusChange.helpSearchApi(section,searchVal));
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
    else {
      if (showDrawer) {
        dispatch(appStatusChange.helpApiCalling(section,offerTableAuthKey));
      }
    }
  }, [dispatch, searchVal, showDrawer,section]);


  return (
    <>
      <div  onClick={handleShowDrawer} style={{margin:"18px 30px"}}>
        <Tooltip title='Help' trigger={['click','hover']}>
        <QuestionCircleOutlined
          style={{ fontSize:window.screen.width>850? "28px":"20px", marginRight: "-10px", cursor: "pointer",color:'#160d08' }}
        />
        </Tooltip>
      </div>
      <Drawer
        placement="right"
        visible={showDrawer}
        onClose={onClose}
        // width={500}
        title={<h2 style={{ textAlign: "center" }}>Help</h2>}
      >
        <div>
          <Input
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ marginLeft: "5%", width: "90%" }}
            allowClear
            type="text"
            placeholder="Type in keyword to search"
          />
        </div>
        {helpDataLoading ? (
          <div style={{ textAlign: "center", margin: "100px 0" }}>
            <Spin size="medium" />
          </div>
        ) : (
          <div style={{ margin: "10px 0" }}>
            <ul>
              {helpData?.length > 0 ? (
                helpData?.map((el) => {
                  return (
                    <li
                      style={{
                        fontSize: "19px",
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                      onClick={() => {
                        setAnswer(el);
                        setShowAns(true);
                      }}
                    >
                      {el?.title}
                    </li>
                  );
                })
              ) : (
                <EmptyData />
              )}
            </ul>
            {helpData.length > 0 && (
                <div  style={{textAlign:"center"}}>
              {/* <Button
                type="link"
                onClick={handleSeeMoreBtn}
              >
                Show more...
              </Button> */}
              </div>
            )}
          </div>
        )}
        {/* Answer */}
        <Drawer
          placement="right"
          visible={showAns}
        //   width={500}
          onClose={onAnsClose}
          title={<h2 style={{ textAlign: "center" }}>Help</h2>}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>{answer?.title}</h2>
            {answer?.videos && answer?.videos.length > 0 ? (
              <PlayCircleTwoTone
                style={{ fontSize: "40px" }}
                onClick={() => {
                  setShowVideo(true);
                }}
              />
            ) : null}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: htmlString }}
            style={{
              padding: "10px 10px",
              textOverflow: "ellipsis",
              overflowY: "auto",
            }}
          />
        </Drawer>
      </Drawer>

      {/* Video Modal */}
      <Modal
        visible={showVideo}
        footer={false}
        onCancel={handleCancel}
        width={"90%"}
        style={{marginTop:'-90px'}}
      >
        <div>
          <iframe
            src={`https://youtube.com/embed/${videoId}`}
            title={answer?.title}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            className={styles.video}
          ></iframe>
        </div>
      </Modal>
      {/* Image modal */}
      <Modal
        visible={visible}
        onCancel={handleImageCancel}
        footer={false}
        width={"100%"}
        style={{marginTop:'-90px'}}
      >
        <Image src={imageSrc} preview={false}></Image>
      </Modal>
    </>
  );
};
export { Help };
