import buildSignatureBlocks from "./helpers/buildSignatureBlocks";
import getFullNamesFromArray from "./helpers/getFullNamesFromArray";
import removeSection from "./helpers/removeSection";
import setDayOfWeek from "./helpers/setDayOfWeek";
import { checkListingContainer, checkSellingContainer, handleSignatureCompletion, showCompanyDetails } from "./helperFunctions";
import imagePlaceholder from "../../Assets/Icons/imagePlaceholder.webp";
import { addDynamicSellerBuyer } from "./helpers/dynamicSellerBuyerTitleOrder";
import { formatDate, formatPhoneNumber } from "../../Common/utils/universalFuntions";
import { transactionReportDynamicFields } from "./helpers/transactionReportDynamicFields";
import { convertTime, createOfferContractTypes, } from "../../Common/commondata/commonData";

const handleDifferentKeyOfClient = ({clientArray, i}) => {
  const newClients = clientArray?.map((client)=>{
    let temp = {
      ...client
    }
    if(temp?.isCorp){ 
      temp.key = (temp?.key) + (i ? `_${i}` : "");
    }
    else{
      temp.key = (temp?.personId || temp?._id) + (i ? `_${i}` : "");
    }
    return temp;
  })
  return newClients;
}

const SetInitialValues = ({ currentUrlId, rootDocData, getTransactionReportData,  transactionEmail, CurrentPdfImportData, sharedDocs, source }) => {
  // filling all the values
  let td = {};
  if (rootDocData?.contractType === "TR") {
    td = getTransactionReportData?.[0].transactionData;

    if (getTransactionReportData) {
      // RE-Sure TRANSACTION DOCUMENTS
      transactionReportDynamicFields(getTransactionReportData, "DOCUMENTS");

      // Signature Details
      transactionReportDynamicFields(getTransactionReportData, "SIGNATURE_DETAILS");
    }
    if (transactionEmail) {
      // Email Communications
      transactionReportDynamicFields(transactionEmail, "EMAIL_COMMUNICATIONS");
    }
    if (sharedDocs) {
      // shared documents table
      transactionReportDynamicFields(sharedDocs, "SHARED_DOCS");
    }
  } else if (source === "OFFER_IMPORT" && CurrentPdfImportData) {
    td = CurrentPdfImportData;
  } else {
    td = JSON.parse(localStorage.getItem(currentUrlId));
  }
  // console.log("localData", td);
  let fillValueFor = rootDocData?.contractType === "TO" && td && td["SellerBrokerLicenseNumber"]?.trim() === td["BuyerBrokerLicenseNumber"]?.trim() && td["SelectedBy"] ? td["SelectedBy"] : null;

  // adding placeholder image for title office in TO form if office not selected
  if (rootDocData?.contractType === "TO") {
    if (!td?.["TitleCompanyLogo"]) {
      const titleOfficeImageElement = Array.from(document.getElementsByName("TitleCompanyLogo"))[0];
      if (titleOfficeImageElement) titleOfficeImageElement.src = imagePlaceholder;
    }
  }

  if (td && Object.keys(td)?.length > 0) {
    for (const key in td) {
      const savedValue = td[key];
      if (key === "Buyers") {
        const buyerStr = getFullNamesFromArray(savedValue);
        const elements = document.querySelectorAll("[data-buyers-list]");
        if (elements) {
          elements.forEach((element) => {
            if (source === "OFFER_IMPORT") {
              element.innerHTML = buyerStr;
            } else {
              element.value = buyerStr;
            }
            element.parentNode.dataset.replicatedValue = buyerStr;
          });
        }

        let i = 0;
        while(true){
          let signatureBlockName = i ? `${"BUYER_" + i}` : `BUYER`;
          let signatureElement = document.querySelectorAll(`[data-signature-block="${signatureBlockName}"]`);
          if(!signatureElement?.length){
            break;
          }
          const currentBuyerClient = handleDifferentKeyOfClient({clientArray: savedValue, i});
          const sigBlock = buildSignatureBlocks(currentBuyerClient, "Buyers", {...rootDocData, key: rootDocData?.key + (i ? `_${i}` : "")}, "BUYER");
          signatureElement?.forEach((element) => {
            // console.log("data-signature-blockelement", element);
            element.innerHTML = sigBlock;
          });

          let signatureBlockName2 = i ? `${"BUYER_WITHDRAW_" + i}` : `BUYER_WITHDRAW`;
          let signatureElement2 = document.querySelectorAll(`[data-signature-block="${signatureBlockName2}"]`);
          const sigBlockWithDraw = buildSignatureBlocks(currentBuyerClient, "Buyers", {...rootDocData, key: rootDocData?.key + (i ? `_${i}` : "")}, "BUYER_WITHDRAW");
          signatureElement2?.forEach((element) => {
            element.innerHTML = sigBlockWithDraw;
          });

          i++;
        }

        if ((rootDocData?.contractType === "TO" || rootDocData?.contractType === "TR") && Array.isArray(savedValue) && savedValue.length) {
          addDynamicSellerBuyer("Buyers", savedValue, rootDocData.contractType);
        }
      } else if (key === "Sellers") {
        const sellerStr = getFullNamesFromArray(savedValue);
        // console.log("Sellers", sellerStr);
        const elements = document.querySelectorAll("[data-sellers-list]");
        if (elements) {
          elements.forEach((element) => {
            element.value = sellerStr;
            element.parentNode.dataset.replicatedValue = sellerStr;
          });
        }

        let i = 0;
        while(true){
          let signatureBlockName = i ? `${"SELLER_" + i}` : `SELLER`;
          let signatureElement = document.querySelectorAll(`[data-signature-block="${signatureBlockName}"]`);
          if(!signatureElement?.length){
            break;
          }
          const currentBuyerClient = handleDifferentKeyOfClient({clientArray: savedValue, i});
          const sigBlock = buildSignatureBlocks(currentBuyerClient, "Sellers", {...rootDocData, key: rootDocData?.key + (i ? `_${i}` : "")}, "SELLER");

          signatureElement?.forEach((element) => {
            element.innerHTML = sigBlock;
          });

          i++;
        }

        if ((rootDocData?.contractType === "TO" || rootDocData?.contractType === "TR") && Array.isArray(savedValue) && savedValue.length) {
          addDynamicSellerBuyer("Sellers", savedValue, rootDocData.contractType);
        }
      } else if (key === "TitleCompanyLogo") {
        const titleOfficeImageElement = Array.from(document.getElementsByName("TitleCompanyLogo"))[0];
        if (savedValue && titleOfficeImageElement) {
          titleOfficeImageElement.src = savedValue;
        }
      } else {
        if (Array.isArray(savedValue)) {
          savedValue.forEach((value) => {
            // console.log("checking if", savedValue, " is selected");
            document.getElementsByName(key).forEach((element, i) => {
              if (element.value === value) {
                element.checked = true;
              }
              if (element.type === "checkbox") {
                if (element.value === savedValue) {
                  element.checked = true;
                }
                if (element.hasAttribute("data-remove-target") && i === 0) {
                  // console.log("section deleted. removing section for", element.name);
                  removeSection(element);
                }
                // if deleted or No Change checkbox is checked already
                if (["No Change", "Deleted"].includes(element.value) && element.checked) {
                  let table31Inputs = document.getElementsByName(element.name)
                   if(table31Inputs && table31Inputs[0]){
                    table31Inputs[0].value=""
                  }
                }
              }
            });
          });
        } else if (typeof (savedValue) === "object") {
          const elements = document.getElementsByName(key);
          if (elements) {
            elements.forEach((element, i) => {
              // console.log("element", element);
              if (element.type !== "checkbox") {
                if (savedValue && savedValue.type === "date") {
                  element.value = formatDate(savedValue.value) ;
                  if (element.hasAttribute("data-setdayofweek") && i === 0) {
                    setDayOfWeek({
                      name: element.name,
                      value: element.value,
                    });
                  }
                } else if (savedValue && savedValue?.type === "mec") {
                  element.value = `${savedValue.value} days after MEC`;
                  if (element.hasAttribute("data-setdayofweek") && i === 0) {
                    setDayOfWeek({
                      name: element.name,
                      value: "",
                    });
                  }
                } else if (savedValue && savedValue.type === "text") {
                  element.value = savedValue.value;
                  if (element.hasAttribute("data-setdayofweek") && i === 0) {
                    setDayOfWeek({
                      name: element.name,
                      value: "",
                    });
                  }
                } else if (savedValue && savedValue.type === "time") {
                  element.value = convertTime(savedValue.value) ;
                }
                if ( element.parentNode.classList.contains("grow-with-input")) {
                  element.parentNode.dataset.replicatedValue = savedValue;
                }
              } else {
                element.checked = false;
              }
            });
          }
        } else {
          // it is a single value
          const elements = document.getElementsByName(key);
          if (elements) {
            elements.forEach((element, i) => {
              if (element.type === "checkbox") {
                if (element.value === savedValue) {
                  element.checked = true;
                } else {
                  element.checked = false;
                }
                if (element.hasAttribute("data-remove-target") && i === 0) {
                  // console.log("section deleted. removing section for", element.name);
                  removeSection(element);
                }
              } else {
                // console.log("element.type", element.name, element.type, savedValue);
                // to prevent filling the fields on TO form Selling and Listing input boxes👇
                if (fillValueFor === "BUYER_AGENT" && checkListingContainer(key, "checkIsPresent") && !td["DoubleSidedTransaction"]) {
                  element.value = ""; // Don't fill the value for seller Agent side (TO form)
                } else if (fillValueFor === "SELLER_AGENT" && checkSellingContainer(key, "checkIsPresent") && !td["DoubleSidedTransaction"]) {
                  element.value = ""; // Don't fill the value for buyer Agent side (TO form)
                } else {
                  if (element.classList.contains("relativeDateInput") && !isNaN(Date.parse(savedValue)) && element.type !== "date") {
                      element.value = formatDate(savedValue);
                      // console.log("element.type inside", element.name, element.type, savedValue);
                  } else if (element.classList.contains("relativeTimeInput")) {
                    element.value = convertTime(savedValue);
                  } else {
                    element.value = savedValue;
                  }
                }
                if (element.parentNode.classList.contains("grow-with-content") || element.parentNode.classList.contains("grow-with-input")) {
                  element.parentNode.dataset.replicatedValue = savedValue;
                }
                if (element.hasAttribute("data-setdayofweek") && i === 0) {
                  setDayOfWeek({
                    name: element.name,
                    value: element.value,
                  });
                }
                if (element.hasAttribute("data-linked-value") && savedValue) {
                  // console.log("element has linked value... setting", ele);
                  const ele = document.querySelector(`[name="${element.dataset.linkedValue}"][data-linked-value="${key}"]`);
                  ele.value = savedValue;
                  ele.checked = true;
                  if (ele.hasAttribute('data-cb-group')) {
                    // Note:- if name and data-cb-group=? is equal then only it will work, otherwise need to remove👇this [data-cb-group="${ele?.name}"] from below
                    const groupElements = document.querySelectorAll(`[name="${ele?.name}"][data-cb-group="${ele?.name}"][type="checkbox"]`);
                    groupElements.forEach((e)=>{
                      if (e.value !== ele.value) {
                        // console.log("elements to be unchecked", e);
                        e.checked = false;
                      }
                    })
                  }
                }
                if (element.hasAttribute("data-enable-target") && savedValue) {
                  document.querySelectorAll(`[data-enable-source="${element.dataset?.enableTarget}"][type="checkbox"]`).forEach((ele) => {
                    ele.checked = true;
                  })
                }

                if (element.classList.contains("formatPhoneNumber") && savedValue) {
                  element.value = formatPhoneNumber(element.value);
                }
              }
            });
          }
        }
      }
    }
  
    // after looping through all values
    // set up signature areas for brokers
    let sellerBroker = {};
    td.SellerBrokerName && (sellerBroker.fullName = td.SellerBrokerName);
    if (td?.SellerBrokerName) {
      let i = 0;
      while(true){
        let signatureBlockName = i ? `${"SELLER_BROKER_" + i}` : `SELLER_BROKER`;
        let signatureElement = document.querySelectorAll(`[data-signature-block="${signatureBlockName}"]`);
        if(!signatureElement?.length){
          break;
        }

        rootDocData?.sellerAgentId && (sellerBroker.personId = rootDocData.sellerAgentId);
        rootDocData?.sellerAgentId && (sellerBroker.key = (rootDocData.sellerAgentId + (i ? `_${i}` : "")));
        let SellerBrokerSigBlock = buildSignatureBlocks([sellerBroker], "Broker", {...rootDocData, key: sellerBroker.key}, "SELLER_AGENT");
        document.querySelectorAll('[data-signature-block="SELLER_BROKER"]')?.forEach((element) => {
          element.innerHTML = SellerBrokerSigBlock;
        });
        i++;
      }
    }

    let buyerBroker = {};
    td.BuyerBrokerName && (buyerBroker.fullName = td.BuyerBrokerName);
    if (td?.BuyerBrokerName) {
      let i = 0;
      while(true){
        let signatureBlockName = i ? `${"BUYER_BROKER_" + i}` : `BUYER_BROKER`;
        let signatureElement = document.querySelectorAll(`[data-signature-block="${signatureBlockName}"]`);
        if(!signatureElement?.length){
          break;
        }
        rootDocData?.buyerAgentId && (buyerBroker.personId = (rootDocData.buyerAgentId));
        rootDocData?.buyerAgentId && (buyerBroker.key = (rootDocData.buyerAgentId + (i ? `_${i}` : "")));
        let BuyerBrokerSigBlock = buildSignatureBlocks([buyerBroker], "Broker", {...rootDocData, key: buyerBroker.key}, "BUYER_AGENT");
        signatureElement?.forEach((element) => {
          element.innerHTML = BuyerBrokerSigBlock;
        });
        i++;
      }
    }

    // To Show the Agent's Office details
    if (createOfferContractTypes.includes(rootDocData?.contractType)) {
      showCompanyDetails(td);
    }
    //For closing company
    if (rootDocData?.contractType === "CI") {
      let closingCompany = {key: rootDocData?.email};
      td.TitleCompanyName && (closingCompany.fullName = td.TitleCompanySignedBy || rootDocData.fullName);
      let ClosingCompanySigBlock = buildSignatureBlocks([closingCompany], "Authorized", rootDocData, "TITLE_COMPANY");
      document.querySelectorAll('[data-signature-block="CLOSING_COMPANY"]')?.forEach((element) => {
        element.innerHTML = ClosingCompanySigBlock;
      });
      const signerTitleInputField = document.getElementById("SignerTitleField")
      if(signerTitleInputField){
        signerTitleInputField.value = td?.SignerTitleInput || ""
      }
    }

    // persisting the signatures
    if (rootDocData && rootDocData.signatureInfo) {
      rootDocData.signatureInfo?.forEach((signatureBlock) => {
        handleSignatureCompletion(signatureBlock);
      });
    }
  }
  // autofill the document date
  const dateFields = document.querySelectorAll('[name="DocumentDate"], [name="OriginalContractDate"]');
  if (td && ((!td["DocumentDate"] && !createOfferContractTypes.includes(rootDocData?.contractType) ) || !td["OriginalContractDate"]) && rootDocData?.builtForSection !== "TEMPLATE") {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const today = `${currentDate.getFullYear()}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
    dateFields?.forEach((el) => {
      const fieldName = el.name;
      if (fieldName === "OriginalContractDate" && (createOfferContractTypes.includes(rootDocData?.contractType) || ["ERTB", "ERTL", "ETCT"].includes(rootDocData?.contractType))) {
        if (!td[fieldName]) {
          td[fieldName] = today;
          el.value = today;
        }
      } else if (fieldName === "DocumentDate") {
        if (!td[fieldName]) {
          td[fieldName] = today;
          el.value = today;
        }
      }
    });
    localStorage.setItem(currentUrlId, JSON.stringify(td));
  }
};

export default SetInitialValues;
