import React from "react";
import { useLocation } from "react-router-dom";
import RtdWindow from "./RtdWindow";

const RtdDesign = ({ tags, selectedData, client,userAuthKey, URLofferTableJSON }) => {

  const location = useLocation();
  let route = location.pathname.split("/")[2];
  return (
    <>
      <div
        style={{
          // marginTop:
          //   route === "inprogress" ? "4%" : route === "listings" ? "4%" : "4%",
          // display: "flex",
          // justifyContent: "center",
        
        }}
      >
        <RtdWindow
          tags={tags}
          selectedData={selectedData}
          client={client}
          userAuthKey={userAuthKey}
          URLofferTableJSON={URLofferTableJSON}
        />
      </div>
    </>
  );
};

export default RtdDesign;
