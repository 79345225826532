import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;
// create offer load data for persist
const getCalendarDetailsRequest = (dataObject, timestamp, responseType) => {
  return axios.get(`${apiURL}/calendar`, {
    params: {
      offerId: dataObject["offerId"],
      timestamp,
      responseType,
    },
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${dataObject["key"]}`,
    },
  });
};

// calendar load data for persist
const getCalendarDataRequest = (id) => {
  const config = {
    method: "GET",
    headers: {
      accept: "application/json",
    },
  };
  return axios(`${apiURL}/url/${id}`, config);
};

const getRemindersListRequest = (dataObject, year) => {
  return axios.get(
    `${apiURL}/reminder?responseType=YEAR&timestamp=${Date.now()}&year=${year}`,
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${dataObject["key"]}`,
      },
    }
  );
};

const getScheduleListRequest = (dataObject, year) => {
  return axios.get(
    `${apiURL}/schedules?responseType=YEAR&timestamp=${Date.now()}&year=${year}`,
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${dataObject["key"]}`,
      },
    }
  );
};

const shareCalendarToClientsRequest = ({payloadData, userAuthKey}) => {
  return axios.post(`${apiURL}/shareCalendarToClients`,{
    offerId: payloadData?.offerId,
    openFor: payloadData?.openFor,
    clientArray: payloadData?.clientArray,
    ...(payloadData?.emailSignatureId && {emailSignatureId: payloadData?.emailSignatureId}),
    ...(payloadData?.emailMessage && {emailMessage: payloadData?.emailMessage}),
    ...(payloadData?.readOnlyEmailsArray && { readOnlyEmailsArray: payloadData?.readOnlyEmailsArray }),

  },
  {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      authorization: `bearer ${userAuthKey}`,
    }
  })
}

export const calendarDataApi = {
  getCalendarDetailsRequest,
  getCalendarDataRequest,
  getRemindersListRequest,
  getScheduleListRequest,
  shareCalendarToClientsRequest,
};
