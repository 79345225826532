import {
    Badge,
    Button,
    Input,
    Popconfirm,
    Space,
    Table,
    Typography,
  } from "antd";
  import React, { useEffect, useState } from "react";
  import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    SearchOutlined,
  } from "@ant-design/icons";
  import moment from "moment";
  import { fjggDataApis } from "./state/actions";
  import { useDispatch, useSelector } from "react-redux";
  import { Loading } from "../Common/components/Loading";
  import { formatAddress } from '../Common/utils/ExtraFunctions';
import { generateFullNameFromObj } from "../Common/utils/universalFuntions";

  const { Text } = Typography;

  const FjggAccess = () => {
    const dispatch = useDispatch();
    const FJGGAcessData = useSelector((state) => state.fjggReducer);
    const [searchedColumn, setsearchedColumn] = useState("");

    const handleSearch = (data) => {
      dispatch(fjggDataApis.getFJGGAccess(data));
      setsearchedColumn("");
    };

    const handleReset = () => {
      dispatch(fjggDataApis.getFJGGAccess());
      setsearchedColumn("");
    };
    let getColumnSearchProps = (dataIndex, x) => ({
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search by Request Id...`}
            value={searchedColumn}
            onChange={(e) => {
              setsearchedColumn(e.target.value ? e.target.value : []);
            }}
            onPressEnter={() => {
              handleSearch(searchedColumn);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                handleSearch(searchedColumn);
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                handleReset();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    });

    const columns = [
      {
        title: "Full Name",
        dataIndex: "fullName",
        key: "fullName",
        ellipsis: true,
        align: "center",
        width: "25%",
        render: (fullName, record) => (
          <span>
         <div>{generateFullNameFromObj(record)}</div>
            {record.personEmail && (
              <Text ellipsis>
                {record.personEmail}
              </Text>
            )}
          </span>
        ),
      },
      {
        title: "Brokerage Name",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        align: "center",
        width: "25%",
        render: (name, record) => (
          <span>
            <div>{name}</div>
            {record.address && (
              <Text ellipsis>
                {formatAddress(record.address)}
              </Text>
            )}
          </span>
        ),
      },
      {
        title: "Requested At",
        dataIndex: "requestedAt",
        key: "requestedAt",
        align: "center",
        width: "25%",
        render: (requestedAt, i) => (
          <Text>{moment(requestedAt).format("MMMM D, YYYY, hh:mm A")}</Text>
        ),
      },
      {
        title: "Request Id",
        dataIndex: "requestId",
        key: "requestId",
        width: "30%",
        ellipsis: true,
        ...getColumnSearchProps(),
        render: (requestId, i) => (
          <div>
          <Text style={{ fontWeight: "bold",display:"flex"}} width="200" copyable ellipsis>{requestId}</Text>
          </div>
        ),
        align: "center",
        defaultSortOrder: "descend",
      },
      {
        title: "Action",
        width: "20%",
        key: "isApproved",
        render: (event, record) => (
          <span style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <Badge status={event.isApproved ? "success" : "error"} />
              {event.isApproved ? "Has Access" : "Grant Access"}
            </div>
            <div>
              <Popconfirm
                title={
                  event.isApproved
                    ? `Are you sure you want to stop having access to ${event.name}'s brokerage account for FJGG Docs & Clauses?`
                    : `Are you sure you want to allow ${event.name} to use FJGG Docs & Clauses in their brokerage account?`
                }
                onConfirm={() => {
                  
                  dispatch(
                    fjggDataApis.grantFJGGAccess({ documentId: event._id,status:event.isApproved }),
                  );
                }}
                // disabled={event.isApproved}
                placement="topRight"
                overlayStyle={{ width: '300px' }}
              >
                <Button
                  key={record._id}
                  icon={
                    event.isApproved ? (
                      <CloseCircleOutlined />
                    ) : (
                      <CheckCircleOutlined />
                    )
                  }
                  size="large"
                  shape="round"
                  style={{ marginLeft: "18px" }}
                />
              </Popconfirm>
            </div>
          </span>
        ),
        align: "center",
      },
    ];

    useEffect(() => {
      dispatch(fjggDataApis.getFJGGAccess());
    }, []);

    return (
      <>
        {FJGGAcessData &&
        FJGGAcessData.FJGGAcessDataLoading &&
        FJGGAcessData.FJGGAcessDataLoading ? (
          <Loading />
        ) : (
          <Table
            bordered
            dataSource={
              FJGGAcessData && FJGGAcessData?.FJGGAcessData
                ? FJGGAcessData?.FJGGAcessData
                : []
            }
            columns={columns}
            pagination={false}
            scroll={{ y: "80vh" }}
          />
        )}
      </>
    );
  };

  export default FjggAccess;
