export const contractStoreConstants = {
  RESET_FORM: "RESET_FORM",

  VALIDATE_FORM_SUCCESS: "VALIDATE_FORM_SUCCESS",
  VALIDATE_FORM_FAILURE: "VALIDATE_FORM_FAILURE",

  AUTO_SAVE_CONTRACT_SUCCESS: "AUTO_SAVE_CONTRACT_SUCCESS",
  AUTO_SAVE_CONTRACT_FAILURE: "AUTO_SAVE_CONTRACT_FAILURE",
  AUTO_SAVE_CONTRACT_STATUS: "AUTO_SAVE_CONTRACT_STATUS",

  GET_ADD_CLAUSES_SUCCESS: "GET_ADD_CLAUSES_SUCCESS",
  GET_ADD_CLAUSES_ERROR: "GET_ADD_CLAUSES_ERROR",
  GET_ADD_CLAUSES_LOADING: "GET_ADD_CLAUSES_LOADING",

  GET_ADD_DOCUMENTS_SUCCESS: "GET_ADD_DOCUMENTS_SUCCESS",
  GET_ADD_DOCUMENTS_ERROR: "GET_ADD_DOCUMENTS_ERROR",
  GET_ADD_DOCUMENTS_LOADING: "GET_ADD_DOCUMENTS_LOADING",

  GET_TRANSACTION_LANE_SUCCESS: "GET_TRANSACTION_LANE_SUCCESS",
  GET_TRANSACTION_LANE_FAILURE: "GET_TRANSACTION_LANE_FAILURE",

  GET_BUYERS_SUCCESS: "GET_BUYERS_SUCCESS",
  GET_BUYERS_ERROR: "GET_BUYERS_ERROR",

  GET_TRANSACTION_REPORT_SUCCESS: "GET_TRANSACTION_REPORT_SUCCESS",
  GET_TRANSACTION_REPORT_ERROR: "GET_TRANSACTION_REPORT_ERROR",

  RESET_VALIDATION_FORM: "RESET_VALIDATION_FORM",

  RESET_SIGNATURE_LOADING: "RESET_SIGNATURE_LOADING",
  SET_TRANSPARENT_DIV_LOADING: "SET_TRANSPARENT_DIV_LOADING",

  DOWNLOAD_PDF_SUCCESS: "DOWNLOAD_PDF_SUCCESS",
  DOWNLOAD_PDF_FAILURE: "DOWNLOAD_PDF_FAILURE",
  DOWNLOAD_PDF_LOADING: "DOWNLOAD_PDF_LOADING",

  SEND_MUL_DOC_LOADING:"SEND_MULDOC_LOADING",
  SEND_MUL_DOC_SUCCESS:"SEND_MULDOC_SUCCESS",
  SEND_MUL_DOC_ERROR:"SEND_MULDOC_ERROR",

};
