export const contractConstants = {
  CONTRACT_PAGE_SUCCESS: "CONTRACT_PAGE_SUCCESS",
  CONTRACT_PAGE_FAILURE: "CONTRACT_PAGE_FAILURE",

  SET_URL_ID: "SET_URL_ID",

  SET_AUTH_KEY_FOR_CONTRACT: "SET_AUTH_KEY_FOR_CONTRACT",
  SET_READ_ONLY_DATA_FOR_CONTRACT: "SET_READ_ONLY_DATA_FOR_CONTRACT",

  CREATE_OFFER_SUCCESS: "CREATE_OFFER_SUCCESS",
  CREATE_OFFER_FAILURE: "CREATE_OFFER_FAILURE",

  RESET_COMPRESSED_VIEW: "RESET_COMPRESSED_VIEW",
  SET_LOADING_SAVE: "SET_LOADING_SAVE",

  VERIFY_OTP_ERROR: "VERIFY_OTP_ERROR",
  SET_IS_DOCUMENT_OPEN: "SET_IS_DOCUMENT_OPEN",

  SET_DRAWER_POSITION: "SET_DRAWER_POSITION",

  GET_EMAIL_CONTENT_SUCCESS: 'GET_EMAIL_CONTENT_SUCCESS',
  GET_EMAIL_CONTENT_LOADING: 'GET_EMAIL_CONTENT_LOADING',
  GET_EMAIL_CONTENT_ERROR: 'GET_EMAIL_CONTENT_ERROR',

  
  GET_CLIENT_DOC_LOADING:"GET_CLIENT_DOC_LOADING",
  GET_CLIENT_DOC_SUCCESS:"GET_CLIENT_DOC_SUCCESS",
  GET_CLIENT_DOC_ERROR:"GET_CLIENT_DOC_ERROR",

  DOCUMENT_BUYERIDS:"DOCUMENT_BUYERIDS",
  DOCUMENT_SELLERIDS:"DOCUMENT_SELLERIDS",

  
  PERSON_DOCS_LOADING: "PERSON_DOCS_LOADING",
  PERSON_DOCS_FAILURE: "PERSON_DOCS_FAILURE",
  PERSON_DOCS_SUCCESS: "PERSON_DOCS_SUCCESS",

};
