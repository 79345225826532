   
    export const TableFourinputBox = [
     "PurchasePrice",
     "EarnestMoney",
     "NewLoan",
     "AssumptionBalance",
     "PrivateFinancing",
     "SellerFinancing",
     "CashAtClosing",
     "TotalLeftColumn",
     "TotalRightColumn",
    ]
    export const OtherTemplateDisabledFields = [
      "OriginalContractDate",
      "TaxLegalDescription",
      "DisableAutoPriceCaluations",
        "AutoFillDates"
    //  "parties and property of buyer",
    //  "Brokerage Firms Name 1",
    //  "Brokerage Firms License 1",
    //  "Broker’s License",
    //  "Firm’s  Address Part1",
    //  "Broker’s Phone No",
    //  "Broker’s Fax No",
    ]
  