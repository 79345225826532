import { contractConstants } from "./action-types";
import { contractDataApi } from "../utils/api";
import { checkIfDisabled, createOfferContractTypes, decrypt, formatPrice, getParsePrice } from "../../Common/commondata/commonData";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { features } from "../features/features";
import { formatAndParse } from "../../Common/utils/universalFuntions";
import { closingActions } from "../../TransactionReport/state/actions";
import { message } from "antd";
import moment from "moment";
/**
 * All Actions for contract Page
 */

/**
 * To update Navbar title action
 * @param {string}
 */

const setUrlIdFun = (urlId) => {
  return {
    type: contractConstants.SET_URL_ID,
    currentUrlId: urlId,
  };
};
const setAuthKeyForContractFun = (key) => {
  return {
    type: contractConstants.SET_AUTH_KEY_FOR_CONTRACT,
    contractAuthKey: key,
  };
};
const setRootLevelDocDataFun = (payload) => {
  return {
    type: contractConstants.SET_READ_ONLY_DATA_FOR_CONTRACT,
    rootDocData: payload,
  };
};
const setLoadingSave = (data) => {
  return {
    type: contractConstants.SET_LOADING_SAVE,
    loadingSave: data,
  };
};

const setError = (data) => {
  return {
    type: contractConstants.VERIFY_OTP_ERROR,
    error: data,
  };
};

const contractError = (error) => {
  return {
    type: contractConstants.CONTRACT_PAGE_FAILURE,
    contractError: error,
    contractLoading: false,
  };
};

const contractSuccess = (response) => {
  return {
    type: contractConstants.CONTRACT_PAGE_SUCCESS,
    contractData: response.data.info,
    contractLoading: false,
  };
};

const getContractData = (id, authKey, contractType) => {
  return (dispatch, getState) => {
    // const token = getState().authentication.token;
    contractDataApi
      .contractPageLoadRequest(id, authKey)
      .then((response) => {
        dispatch(contractSuccess(response));
        if (contractType !== "NTT") {
          dispatch(contractStoreHandle.getAddClausesData(authKey));
          dispatch(contractStoreHandle.getAddDocumentsData(authKey));
        }
      })
      .catch((error) => {
        dispatch(contractError(error));
      });
  };
};
const DocumentBuyerDetails= (response) => {
  return {
    type: contractConstants.DOCUMENT_BUYERIDS,
    documentBuyerIds:response,
    
  };
};
const DocumentSellerDetails= (response) => {
  return {
    type: contractConstants.DOCUMENT_SELLERIDS,
    documentSellerIds:response,
  };
};
const getPersistDeatailsDataOnly=(documentId, token)=>{
  return (dispatch, getState) => {
    contractDataApi
      .getPersistdetailsDataRequest(documentId,token)
      .then((response) => {
      let document_buyerIds=response.data?.info?.buyerIds
      let document_sellerIds=response.data?.info?.sellerIds
      dispatch(DocumentBuyerDetails(document_buyerIds))
     dispatch(DocumentSellerDetails(document_sellerIds))
      })
}}
const getPersistDeatailsData = (documentId, buildData, source, persistDateCalendar, urlId) => {
 
  return (dispatch, getState) => {
    contractDataApi
      .getPersistdetailsDataRequest(documentId, buildData.token)
      .then((response) => {
      let document_buyerIds=response.data?.info?.buyerIds
      let document_sellerIds=response.data?.info?.sellerIds
        let offerId= response.data?.info?.offerId?._id;
        let propertyId= response.data?.info?.propertyId?._id
        dispatch(DocumentBuyerDetails(document_buyerIds))
        dispatch(DocumentSellerDetails(document_sellerIds))
        localStorage.setItem(buildData._id, JSON.stringify(response.data.info?.transactionData));
        let docResponse = response.data?.info;
        if(offerId){
          docResponse.offerData = docResponse?.offerId;
          docResponse.offerId = offerId;
        }
        if(propertyId){
          docResponse.propertyData = docResponse?.propertyId;
          docResponse.propertyId = propertyId;
        }
        let checkForDisable = checkIfDisabled({openFor: buildData?.openFor, contractData: docResponse.contractId, offerStatus: docResponse?.offerData?.status, actualPropertyStatus: docResponse?.propertyData?.status || docResponse?.sellerDocumentStatus, sellerDocumentStatus: docResponse?.sellerDocumentStatus, buyingDocumentStatus: docResponse?.buyingDocumentStatus, sellingStatus: docResponse?.offerData?.sellingStatus, buyingStatus: docResponse?.offerData?.buyingStatus});
        docResponse.checkForDisable = checkForDisable;
        let contractId;
        if (source === "CONTRACT") {
          if (docResponse?.documentType === "TEMPLATE" && !docResponse?.contractId?._id) {
          //  contractId="64f1e5e7c89d8d4ec138c7f7" // old CBSR
           contractId="66ab7a6a7bfcc2a4deedb08d" // new CBSR
          } else {
            contractId=docResponse?.contractId?._id
         }
          dispatch(getContractData(contractId, buildData?.token, docResponse?.contractType));
        } 
        dispatch(getCreateOfferSuccess(docResponse, documentId, buildData, persistDateCalendar,offerId));
      })
      .catch((error) => {});
  };
};

// get all create offer params from the id
const getCreateOfferError = (error) => {
  return {
    type: contractConstants.CREATE_OFFER_FAILURE,
    createOfferError: error,
    createOfferLoading: false,
  };
};

const getCreateOfferSuccess = (originalText, documentId, buildData, persistDateCalendar,offerId) => {
  //originalText====Document Response or get Url Response when documentId is not Present
  let combinedData;
  if (documentId) {
    let createOfferDatatemp, tempRootDocData;
    let { transactionData={}, ...rootDocData } = originalText || {};
    let { urlData = {}, ...rootData } = buildData || {};
    createOfferDatatemp = {
      ...(urlData),
      ...(transactionData),
    };
    tempRootDocData = {
      ...(rootData?{...rootData}: {}),
      urlId: buildData._id,
      ...(rootDocData && rootDocData),
    };
    if (persistDateCalendar) {
      createOfferDatatemp["documentId"] = null;
    }
    combinedData = {
      createOfferData: createOfferDatatemp,
      rootDocData: tempRootDocData,
    };
  } else {
    let { urlData={}, ...rootDocData } = originalText || {};
    combinedData = {
      createOfferData: urlData,
      rootDocData: {
        ...(rootDocData && rootDocData),
        urlId: rootDocData?._id,
      },
    };
  }
  const createOfferData = combinedData?.createOfferData||{};
  const rootDocData = combinedData?.rootDocData || {};
  let paramsData = {};
  if (rootDocData) {
    paramsData = {
      ...createOfferData,
    };
    if (!rootDocData.documentId && rootDocData?.builtForSection === "DOCUMENT" && createOfferContractTypes.includes(rootDocData?.contractType)) {
        let purchasePrise = formatAndParse(createOfferData["PurchasePrice"]);
        let earnestMoney = getParsePrice((purchasePrise * 1) / 100);
        let newLoan = getParsePrice((purchasePrise * 95) / 100);
        let cashAtClosing = getParsePrice((purchasePrise * 4) / 100);
        let totalAmount = formatPrice(purchasePrise, true);
        paramsData["PurchasePrice"] = getParsePrice(purchasePrise);
        paramsData["EarnestMoney"] = createOfferData["EarnestMoney"] ? formatPrice(createOfferData["EarnestMoney"], true):earnestMoney;
        paramsData["NewLoan"] = newLoan;
        paramsData["AssumptionBalance"] = "0.00";
        paramsData["PrivateFinancing"] = "0.00";
        paramsData["SellerFinancing"] = "0.00";
        paramsData["CashAtClosing"] = cashAtClosing;
        paramsData["TotalLeftColumn"] = totalAmount;
        paramsData["TotalRightColumn"] = totalAmount;
      // date logic
      const currentDate = moment();
      const closingDate = moment(createOfferData.ClosingDate); 
   
      if (closingDate && closingDate.diff(currentDate, 'days') > 29) {
          paramsData.AutoFillDates = "AutoFillDates";
          features.autoFillDateLogic(rootDocData.urlId, "Initial");
      }
    } else {
      paramsData.urlId= rootDocData.urlId;
    }
    let temp1Element = JSON.parse(localStorage.getItem(rootDocData.urlId)) || {};
    localStorage.setItem(rootDocData.urlId, JSON.stringify({ ...temp1Element, ...paramsData }));
  }
  return {
    type: contractConstants.CREATE_OFFER_SUCCESS,
    ...combinedData,
    createOfferLoading: false,
    offerId:offerId
  };
};
/*
first stape
when the offer document loads it will always fetch url data
*/
const getCreateOfferData = ({urlId,  unmounted, sourceAxios,sendEmail}) => {
  return (dispatch) => {
    contractDataApi
      .getCreateOfferDataRequest({urlId,  unmounted, sourceAxios})
      .then((response) => {
        let originalText = decrypt(response.data.info);
        if (!originalText) {
          return dispatch(
            setError({
              message: "Document has been saved or closed, cannot access it once saved or closed",
              statusCode: 400,
            })
          );
        }
        console.log("originalText", originalText);
        //In this location, we keep urlId in local storage.
        if (unmounted?.value) {
          return
        }
        localStorage.setItem(urlId, JSON.stringify(originalText.urlData||{}));
        dispatch(setAuthKeyForContractFun(originalText.token));
        
        if (originalText?.contractType === 'TR') {
          dispatch(contractStoreHandle.getTransactionReportMethod(originalText.offerId, originalText.token));
          dispatch(closingActions.transactionReportEmail(originalText.offerId, originalText.token));
          dispatch(closingActions.getSharedDocs(originalText.offerId, originalText.token));
          if (originalText.documentId) {
            console.log("A");
            dispatch(getPersistDeatailsData(originalText?.documentId, originalText, "CONTRACT"));
          }  else {
            console.log("B");
            if (originalText?.contractId) {
              dispatch(getContractData(originalText?.contractId, originalText?.token, originalText?.contractType));
            }
             dispatch(getCreateOfferSuccess(originalText, null, null));
          }
          setTimeout(() => {
            dispatch(contractStoreHandle.autoSaveContract(urlId, originalText.token, null, {}));
          }, 3000);
        }else {
          if (originalText.documentId) {
            console.log("A");
            dispatch(getPersistDeatailsData(originalText?.documentId, originalText, "CONTRACT"));
            // offerCreation with template
          }  else {
            console.log("B");
            if (originalText?.contractId) {
              dispatch(getContractData(originalText?.contractId, originalText?.token, originalText?.contractType));
            }
             dispatch(getCreateOfferSuccess(originalText, null, null));
          }
        }
      })
      .catch((error) => {
        if (!unmounted?.value) {
          dispatch(getCreateOfferError(error));
        }
      });
  };
};
const resetCompressedViewData = () => ({
  type: contractConstants.RESET_COMPRESSED_VIEW,
});
const setDrawerPosition = (payload) =>({
  type: contractConstants.SET_DRAWER_POSITION,
  setDrawer:payload,
});

const getEmailContents = (payload, accessToken) =>{
  return (dispatch, getState) => {
    dispatch(getEmailContentsLoading(true));
    contractDataApi.getEmailContentsRequest(payload, accessToken)
    .then((response)=> {
      dispatch(getEmailContentsSuccess(response))
    })
    .catch((error)=> {
      dispatch(getEmailContentsError(true));
    })
    .finally(() => {
      dispatch(getEmailContentsLoading(false));
    });
  }
};

const getEmailContentsSuccess = (response) => {
  return {
    type: contractConstants.GET_EMAIL_CONTENT_SUCCESS,
    setEmailData: response?.data?.info,
    setEmailLoading: false,
    setEmailError: false,
  }
}

const getEmailContentsLoading = (payload) => {
  return {
    type: contractConstants.GET_EMAIL_CONTENT_LOADING,
    setEmailLoading: payload,
    setEmailError: false,
  }
}

const getEmailContentsError = (payload) => {
  return {
    type: contractConstants.GET_EMAIL_CONTENT_ERROR,
    setEmailLoading: false,
    setEmailError: payload,
  }
}
// Get Property Docs
const getClientDocRequestLoading = (error) => {
  return {
    type: contractConstants.GET_CLIENT_DOC_LOADING,
    uploadPdfError: error,
    uploadPdfLoading: false,
  };
};

const getClientDocRequestSuccess = (response) => {
  return {
    type: contractConstants.GET_CLIENT_DOC_SUCCESS,
    propertyDocs: response?.data?.info,
    uploadPdfLoading: false,
  };
};

const getClientDocRequestError = (error) => {
  return {
    type: contractConstants.GET_CLIENT_DOC_ERROR,
    uploadPdfError: error,
    uploadPdfLoading: false,
  };
};

const getPropertyDocsFun = ({params, unmounted, source,contractAuthKey}) => {
  return (dispatch, getState) => {
    dispatch(getClientDocRequestLoading());
    contractDataApi
      .getClientDocRequest({params, source,contractAuthKey})
      .then((response) => {
        if(!unmounted.value){
          dispatch(getClientDocRequestSuccess(response));
        }
      })
      .catch((error) => {
        if(!unmounted.value){
          dispatch(getClientDocRequestError(error));
          message.error(
            error?.response?.data?.message || "Error occured while getting property docs data!",
          );
        }
      });
  };
};
//Get Person Docs
const getPersonDocsLoading = () => {
  return {
    type: contractConstants.PERSON_DOCS_LOADING,
  };
};
const getPersonDocsError = (error) => {
  return {
    type: contractConstants.PERSON_DOCS_FAILURE,
    personDocsError: error,
  };
};

const getPersonDocsSuccess = (response) => {
  return {
    type: contractConstants.PERSON_DOCS_SUCCESS,
    personDocs: response.data.info,
  };
};

const getPersonDocs = ({id, unmounted, source,contractAuthKey}) => (dispatch) => {
  dispatch(getPersonDocsLoading());
  return contractDataApi
    .getPersonDocsRequest({id, source,contractAuthKey})
    .then((response) => {
      if(!unmounted.value){
        dispatch(getPersonDocsSuccess(response));
      }
    })
    .catch((error) => {
      if(!unmounted.value){
        message.error(error?.response?.data?.message || "We encountered an issue while getting person documents. Please try again later!", 2);
        dispatch(getPersonDocsError(error));
      }
    });
};
export const contractDataHandle = {
  getContractData,
  resetCompressedViewData,
  getCreateOfferData,
  setLoadingSave,
  setError,
  setUrlIdFun,
  setAuthKeyForContractFun,
  setRootLevelDocDataFun,
  setDrawerPosition,
  getEmailContents,
  getPropertyDocsFun,
  getPersonDocs,
  getPersistDeatailsDataOnly
};
