import { AppDataApi } from "../utils/api";
import { appConstants } from "./action-types";
/**
 * All Actions for App
 */

/**
 * To update Navbar title action
 * @param {string} navbar_title
 */

const updateNavbarTitle = (text) => {
  return {
    type: appConstants.UPDATE_APP_TITLE,
    appTitle: text,
  };
};

//Help Global Search
const getHelpSearchLoading = (response) => {
    return {
        type: appConstants.HELP_GLOBAL_SEARCH_LOADING,
        helpData: response,
        helpDataLoading: false,
    };
  }

  const getHelpSearchSuccess = (response) => {
    return {
      type: appConstants.HELP_GLOBAL_SEARCH_SUCCESS,
      helpData: response,
      helpDataLoading: false,
    };
  }
  const getHelpSearchError = (error) => {
    return {
      type: appConstants.HELP_GLOBAL_SEARCH_ERROR,
      helpDataError: error,
      helpDataLoading: false,
    };
  }
  const helpSearchApi = (tabName,query) => {
    return (dispatch, updateState) => {
      dispatch(getHelpSearchLoading());
      AppDataApi.getHelpSeachData(tabName,query)
        .then((response) => {
          dispatch(getHelpSearchSuccess(response?.data?.info));
        })
        .catch((error) => {
          dispatch(getHelpSearchError(error));
        });
    };
  }

  //Help
  const getHelpLoading = (response) => {
    return {
        type: appConstants.HELP_DATA_LOADING,
        helpData: response,
        helpDataLoading: true,
    };
  }

  const getHelpSuccess = (response) => {
    return {
      type: appConstants.HELP_DATA_SUCCESS,
      helpData: response,
      helpDataLoading: false,
    };
  }
  const getHelpError = (error) => {
    return {
      type: appConstants.HELP_DATA_ERROR,
      helpDataError: error,
      helpDataLoading: false,
    };
  }
  const helpApiCalling = (query,offerTableAuthKey) => {
    return (dispatch, updateState) => {
      dispatch(getHelpLoading());
      AppDataApi.getHelpData(query,offerTableAuthKey)
        .then((response) => {
          dispatch(getHelpSuccess(response?.data?.info));
        })
        .catch((error) => {
          dispatch(getHelpError(error));
        });
    };
  }
export const appStatusChange = {
  updateNavbarTitle,
  helpApiCalling,
  helpSearchApi,
};
