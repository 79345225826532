import React, { useEffect, useState, useMemo } from "react";
import {
  // Calendar,
  Modal,
  Typography,
  Row,
  Select,
  Col,
  Card,
  Divider,
  PageHeader,
  Button,
  Badge,
  DatePicker,
  Collapse,
} from "antd";
import calDa from "./calData";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { stack as Menu } from "react-burger-menu";
import { useParams,useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../Common/components/Loading";
import daysData from "../Common/commondata/commonData";
import { calendarHandle } from "./state/actions";
import Events from "./components/Events";
import CustomWeekView from "./components/CustomWeekView";
import styles from "./styles/Calendar.module.css";
import "./styles/calendarCustom.css";
import { RBCToolbar } from "./components/RBCToolbar";
import {
  InsertRowAboveOutlined,
  CaretRightOutlined,
  CloseCircleOutlined,
  CaretLeftOutlined,
  RedoOutlined
} from "@ant-design/icons";
import { segsOverlap } from "react-big-calendar/lib/utils/eventLevels";
import FullCalendar from "@fullcalendar/react";
import timegrid from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import dotEnv from "dotenv";
import { d } from "../CreateOffer/features/helperFunctions";
import { useRef } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CustomCalendar from "./CustomCalendar";
import { useMediaQuery } from "react-responsive";
import { detectMobile, detectWebView } from "../Common/utils/universalFuntions";
const localizer = momentLocalizer(moment);
const { Option } = Select;
const { Title } = Typography;
const {Panel} = Collapse;
const isInWebView = detectWebView() || detectMobile();
moment.locale("ko", {
  week: {
    dow: 1,
    doy: 1,
  },
});
// Calendar.momentLocalizer(moment);

dotEnv.config();
const apiURL = process.env.REACT_APP_ADMIN_BASE_URL;

function getMonthData(value) {
  if (value.month() === 8) {
    return 1394;
  }
}

function convertSinglified(events) {
  if (!(events && events.length > 0)) return;
  let fieldObj = events[0];
  fieldObj.type = "";
  fieldObj.fullAddress = "";
  fieldObj.description = "";
  let keywiseArrays = {};
  for (let i in fieldObj) {
    let arr = [];
    for (let j = 0; j < events.length; j++) {
      arr.push(events[j][i]);
    }
    keywiseArrays[i] = arr;
   }
  let hmp = new Map();
  let arr = [];
  for (let i = 0; i < keywiseArrays.start.length; i++) {
    if (hmp.has(new Date(keywiseArrays.start[i]).getTime())) continue;
    let temp = {};
    for (let a in fieldObj) {
      temp[a] = [];
    }
    for (let j = i; j < keywiseArrays.start.length; j++) {
      if (
        new Date(keywiseArrays.start[i]).getTime() + 300000 >
          new Date(keywiseArrays.start[j]).getTime() &&
        ((keywiseArrays.eventType[i] != undefined &&
          keywiseArrays.eventType[j] != undefined &&
          keywiseArrays.eventType[i] === keywiseArrays.eventType[j]) ||
          (keywiseArrays.type[i] != undefined &&
            keywiseArrays.type[j] != undefined &&
            keywiseArrays.type[i] === keywiseArrays.type[j]))
      ) {
        for (let b in fieldObj) {
          temp[b].push(keywiseArrays[b][j]);
        }
        hmp.set(new Date(keywiseArrays.start[j]).getTime(), 1);
      }
    }
    arr.push(temp);
  }
  for (let i = 0; i < arr.length; i++) {
    let obj = arr[i];
    for (let a in obj) {
      obj[`${a}1`] = obj[a];
      obj[a] = obj[a][0];
    }
  }
  return arr;
}

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const monthNames1 = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let getMonthDatble = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

let getmonth = (currentMonth, year) => {
  let newMonths = monthNames.slice(0, currentMonth);
  newMonths = newMonths.map((month, i) => month + " " + (+year + +1));
  let currentMonths = monthNames.slice(currentMonth);
  currentMonths = currentMonths.map((month, i) => month + " " + +year);

  return [...currentMonths, ...newMonths];
};

function CalenderComponent() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get('source');
  const viewType = searchParams.get('viewType') || 'month';
  let { documentId } = useParams();
  let date = new Date();
  const [selectedYear, setYear] = useState(date.getFullYear());
  const [changedDate, setchangeDateHandle] = useState(
    String(new Date().getDate()).padStart(2, "0")
  );
  const queryCheck = useMediaQuery({maxWidth: "600px"})
  const [disableBtn, setdisableBtn] = useState(true);

  const [setView, updateSetView] = useState({
    week: true,
  });
  const [setDefaultView, updateSetDefaultView] = useState("week");

  const getInitialView = () => {
    switch (viewType) {
      case 'week':
        return 'timeGridWeek'; // Week view
      case 'month':
        return 'dayGridMonth'; // Month view
      default:
        return 'dayGridMonth'; // Default view
    }
  };

  let onDateChange = (e) => {
    let day = String(new Date(e._d).getDate()).padStart(2, "0");
    setchangeDateHandle(day);
  };

  const calendarRef = useRef(null);
   // Function to reset the calendar to the current week
   const resetToCurrentWeek = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(new Date());
    }
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (source === "HOME_CALENDAR" && viewType === "month") {
      let currentTimeStamp = moment().valueOf();
      dispatch(calendarHandle.getCalendarData({id: documentId, source, payloadTimestamp: currentTimeStamp}));
    } else {
      dispatch(calendarHandle.getCalendarData({id: documentId, source}));
    }
  }, [documentId, dispatch, source]);

  useEffect(() => {
    return () => {
      dispatch(calendarHandle.resetCalendar());
    };
  }, [dispatch, documentId, selectedYear]);

  let {
    calendarData,
    calendarDataEncrypt,
    getRemindersData,
    getSchedulesData,
    shareCalendarLoading,
    calendarDataLoading
  } = useSelector((state) => state.calendarData);

  function gencolor(deg) {
    return `hsl(${deg},50%,50%)`;
  }
  let degrees = 60;
  let colorMap = new Map();
  calendarData?.map((e, i) => {
    if (colorMap.has(e.fullAddress)) {
      return e;
    } else {
      colorMap.set(e.fullAddress, gencolor(degrees));
      degrees += 45;
      return e;
    }
  });

  function calcStartTime(element) {
    let startTime;
    if (element.eventType === "PUBLIC HOLIDAY") {
      startTime = element.startTimestamp
        ? element.startTimestamp
        : element.eventDate
        ? new Date(
            new Date().getFullYear(),
            parseInt(element.eventDate.slice(5, 7)) - 1,
            parseInt(element.eventDate.slice(8))
          ).getTime()
        : 0;
    } else {
      startTime = element.startTimestamp
        ? element.startTimestamp
        : element.eventTimestamp;
    }
    return startTime;
  }

  function calcEndTime(element) {
    let endTime;
    if (element.eventType === "PUBLIC HOLIDAY") {
      endTime = element.endTimestamp
        ? element.endTimestamp
        : element.startTimestamp
        ? element.startTimestamp
        : element.eventDate
        ? new Date(
            new Date().getFullYear(),
            parseInt(element.eventDate.slice(5, 7)) - 1,
            parseInt(element.eventDate.slice(8))
          ).getTime() +
          8.64e7 -
          60000
        : 0;
    } else {
      endTime = element.endTimestamp
        ? element.endTimestamp
        : element.eventTimestamp
        ? element.eventTimestamp + 8.64e7 - 60000
        : element.startTimestamp;
    }
    return endTime;
  }

  const eventsMemoizedValues = React.useMemo(() => {
    let calendarDataTempVeriable = [];
    let reminderDataTempVariable = [];
    if (calendarData !== null) {
      calendarData.forEach((element, index, array) => {
        let startTime = calcStartTime(element);
        let endTime = calcEndTime(element);
        element.start = new Date(parseInt(startTime)); // + 8 * 3600000
        element.end = new Date(parseInt(startTime + 1 * 3600000));
        
        if (element.dashboardType === "CONTRACT_REMINDER" && element.fullAddress) {
          element.allDay = true;
          element.defaultAllDay = true;
          element.type = element.dashboardType;
          reminderDataTempVariable.push(element);
        }
        else{
          calendarDataTempVeriable.push(element);
        }
        if (element.eventType && element.eventType === "PUBLIC HOLIDAY") {
          element.allDay = true;
          element.defaultAllDay = true;
        }
        if (colorMap.has(element.fullAddress)) {
          element.backgroundColor = colorMap.get(element.fullAddress);
          element.bgcolor = colorMap.get(element.fullAddress);
        } else {
          colorMap.set(element.fullAddress, gencolor(degrees));
          degrees += 50;
        }
        element.border = "none";
      });
     if (getRemindersData?.length > 0) {
       getRemindersData?.forEach((element, index, array) => {
          let endTime = element.endTimestamp
            ? element.endTimestamp
            : element.duration
            ? element.startTimestamp + element.duration * 3600000
            : element.startTimestamp + 1 * 3600000;
            element.start = new Date(parseInt(element.startTimestamp));
          element.end = new Date(parseInt(endTime));
          element.allDay = true;
          element.defaultAllDay = true;
          if (element.type === "CONTRACT_REMINDER" && element.fullAddress) {
            if (colorMap.has(element.fullAddress)) {
              element.backgroundColor = colorMap.get(element.fullAddress);
              element.bgcolor = colorMap.get(element.fullAddress);
            } else {
              colorMap.set(element.fullAddress, gencolor(degrees));
              degrees += 50;
            }
          } else {
            element.backgroundColor = "green";
            element.bgcolor = "green";
          }

          reminderDataTempVariable.push(element);
          // calendarDataTempVeriable.push(element);
        });
      }
      if (getSchedulesData?.length > 0) {
        getSchedulesData?.forEach((element, index, array) => {
          // let endTime = element.endTimestamp ? element.endTimestamp : element.duration ? element.startTimestamp + element.duration * 3600000 : element.startTimestamp + 1 * 3600000;
          element.start = new Date(parseInt(element.startTimestamp));
          element.end = new Date(parseInt(element.endTimestamp));
          element.backgroundColor = "#3788d8";
          element.bgcolor = "#3788d8";
          reminderDataTempVariable.push(element);
        });
      }
      return [
        ...convertSinglified(calendarDataTempVeriable),
        ...reminderDataTempVariable,
      ];
    }
  }, [calendarData, getRemindersData, getSchedulesData]);


  const [selectedData, setSelectedData] = useState(
    calendarData && calendarData[date.getMonth()]
  );

  // set badge render
  const [badgeColor, setBadgeColor] = useState(null);
  const history = useHistory();

  let dateCellRender = (value, selectedData) => {
    return (
      // <ul className="events">
      <>
        {selectedData &&
          selectedData.map((item, index) => {
            if (item["eventDate"] === moment(value).format("YYYY-MM-DD")) {
              if (item["eventType"] === "EVENT") {
                // if (badgeColor === null) {
                //   let itemBadge = {};
                //   itemBadge[`${item["eventDate"]}`] = { event: true };
                //   setBadgeColor({ ...badgeColor, ...itemBadge });
                //   return <Badge status="success" />;
                // } else {
                //   if (badgeColor[item["eventDate"]] !== item["eventDate"]) {
                //     let itemBadge = {};
                //     itemBadge[`${item["eventDate"]}`] = { event: true };
                //     setBadgeColor({ ...badgeColor, ...itemBadge });
                //     return <Badge status="2db7f5" />;
                //   }
                // }
                return <Badge status="success" />;
              } else if (item["eventType"] === "BIRTHDAY") {
                return <Badge key={index} color="#2db7f5" />;
              } else if (item["eventType"] === "PUBLIC HOLIDAY") {
                return <Badge key={index} color="#2db7f5" />;
              } else {
                return <Badge key={index} status="warning" />;
              }
            }
            return null;
          })}
        {/* </ul> */}
      </>
    );
  };
   const restCalenderData=()=>{
    dispatch(calendarHandle.resetCalendar());
   }
  const [selectedMonth, setMonth] = useState(monthNames[date.getMonth()]);
  let handleMonth = (data, month) => {
    setSelectedData(data);
    setMonth(monthNames[parseInt(month) - 1]);
    setMonthVisible(false);
  };

  let handleSelect = (event) => {
    setYear(event);
  };
  useEffect(() => {
    let mon = getMonthDatble[new Date().getMonth()];
    calendarData && setSelectedData(calendarData[mon]);
  }, [calendarData]);

  const [modalVisivle, setModalVisivle] = useState({ data: [], status: false });
  const [event, setEvent] = React.useState(null);
  const [currentDate, setCurrentDate] = useState("");

  let handleDateChange = (value, selectedData) => {
    let tempArray = [];
    selectedData &&
      selectedData.forEach((ele, index) => {
        if (moment(value).format("YYYY-MM-DD") === ele.eventDate) {
          tempArray.push(ele);
        }
      });
    setModalVisivle({ data: tempArray, status: true });
  };

  let handleCancel = () => {
    setModalVisivle({ data: [], status: false });
  };

  let redirectHome = async () => {
    await window?.ReactNativeWebView?.postMessage("WINDOW_CLOSED");
  };

  const [monthVisible, setMonthVisible] = useState(false);

  let handleMonthModal = () => {
    setMonthVisible(true);
  };

  const CalendarToolbar = (props) => {
    let redirectFlag = JSON.parse(localStorage.getItem("persistKey"));

    let redirectHome = async () => {
      await window?.ReactNativeWebView?.postMessage("WINDOW_CLOSED");
    };

    let handleRedirect = () => {
      if (redirectFlag && redirectFlag["redirectTo"] === "webApp") {
        // window.location = apiURL;
        window.history.back();
      } else {
        redirectHome();
      }
    };

    function scrollSynchronizeY(element1, element2) {
      const el1 = document.querySelector(element1);
      const el2 = document.querySelector(element2);
      el2.style.height = el1.clientHeight + "px";
      el1.addEventListener("scroll", function (e) {
        document.querySelector(element2).scrollTop = e.target.scrollTop;
      });
      el2.addEventListener("scroll", function (e) {
        document.querySelector(element1).scrollTop = e.target.scrollTop;
      });
    }
    function scrollSynchronizeX(element1, element2) {
      const el1 = document.querySelector(element1);
      const el2 = document.querySelector(element2);
      el2.style.width = el1.clientWidth + "px";
      el1.addEventListener("scroll", function (e) {
        document.querySelector(element2).scrollLeft = e.target.scrollLeft;
      });
      el2.addEventListener("scroll", function (e) {
        document.querySelector(element1).scrollLeft = e.target.scrollLeft;
      });
    }

    React.useEffect(() => {
      scrollSynchronizeY(".rbc-time-view", ".rbc-time-gutter");
      scrollSynchronizeX(".rbc-time-view", ".rbc-time-header-cell");
      const rbcTImeGutter = document.querySelector(".rbc-time-gutter");
      if (rbcTImeGutter) rbcTImeGutter.scrollTop = 240;
      const rbcToday = document.querySelector(".rbc-today");
      if (rbcToday) rbcToday.scrollIntoView({ inline: "center" });
      const timeCol = document.querySelectorAll(".rbc-time-gutter span");
      Array.from(timeCol).forEach((e, i) => {
        if (e.textContent.match(/8:00 AM/)) {
          e.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
    }, []);

    React.useEffect(() => {
      const buttons = document.querySelectorAll(".rbc-button-link");
      Array.from(buttons).forEach((e, i) => {
        // const new_element = e.cloneNode(true);
        // e.parentNode.replaceChild(new_element, e);

        e.addEventListener("click", function (ev) {
          const toolbar = document.querySelector(".rbc-calendar").children[0];
          if (toolbar && event) {
            event.source.postMessage(
              {
                message: "Date from calendar",
                year: selectedYear,
                monthExtract: toolbar.textContent,
                dayExtract: ev.target.innerText,
              },
              event.origin
            );
          }
        });
      });
      const controls = [
        document.getElementById("caretLeftOutlined"),
        document.getElementById("caretRightOutlined"),
      ];
      controls.forEach((e, i) => {
        e.addEventListener("click", function (ev) {
          const toolbar = document.querySelector(".rbc-calendar").children[0];
          if (toolbar && event) {
            if (e.id == "caretLeftOutlined") {
              event.source.postMessage(
                {
                  message: "Date Toolbar",
                  content: toolbar.textContent,
                  click: "PREV",
                },
                event.origin
              );
            } else if (e.id == "caretRightOutlined") {
              event.source.postMessage(
                {
                  message: "Date Toolbar",
                  content: toolbar.textContent,
                  click: "NEXT",
                },
                event.origin
              );
            }
          }
        });
      });
      const toolbar = document.querySelector(".rbc-calendar").children[0];
      toolbar.style.position = "relative";
      toolbar.style.top = "-10px";
    }, []);

    let handleMenuChange = (e) => {
      switch (e.target.id) {
        case "0": {
          props.onView("day");
          updateSetView({
            day: true,
          });
          // updateSetDefaultView("day");
          break;
        }
        case "1": {
          props.onView("month");
          updateSetView({
            month: true,
          });
          // updateSetDefaultView("month");
          break;
        }
        case "2": {
          props.onView("agenda");
          updateSetView({
            agenda: true,
          });
          // updateSetDefaultView("agenda");
          break;
        }
        case "3": {
          props.onView("week");
          updateSetView({
            week: true,
          });
          // updateSetDefaultView("week");
          break;
        }
        case "4": {
          handleRedirect();
          break;
        }
        default:
          break;
      }
    };

    return (
      <div
        style={{
          minHeight: "60px",
          display: "grid",
          gridTemplateColumns: "1fr 5fr 1fr",
          alignContent: "center",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {window.screen.width < 800 ? (
          <div
            style={{
              fontSize: "18px",
            }}
            onClick={redirectHome}
          >
            X
          </div>
        ) : (
          <div></div>
        )}
        <span
          style={{
            textAlign: "center",
            fontSize: "24px",
            fontWeight: "bold",
            color: "#1d4397d1",
            // border:"1px solid red"
          }}
        >
          <CaretLeftOutlined
            onClick={() => props.onNavigate("PREV")}
            style={{ marginRight: "10px" }}
            id="caretLeftOutlined"
          />
          {props.label}
          <CaretRightOutlined
            onClick={() => props.onNavigate("NEXT")}
            style={{ marginLeft: "10px" }}
            id="caretRightOutlined"
          />
        </span>
      </div>
    );
  };
  const getHeaderTimeGrid = (props) => {
    return (
      <div>
        {daysData.daysData[props.date.getUTCDay()][0]}
        {props.date.getDate()}
        {/* <h2>{label}</h2>
        {views.map((view) => {
          return (
            <button key={view} type="button" onClick={() => onView(view)}>
              {view}
            </button>
          );
        })} */}
      </div>
    );
  };

  const handleSelected = (event) => {
    setModalVisivle({ data: [event], status: true });
  };

  const MyWeekEvent = (event) => {
    return null;
  };

  let eventStyleGetter = (event, start, end, isSelected) => {
    let color;
    switch (event.eventType) {
      case "EVENT":
        color = colorMap.has(event.fullAddress)
          ? colorMap.get(event.fullAddress)
          : "#80ebaf";

        break;
      case "BIRTHDAY":
        color = "#c1e84e";
        break;
      case "PUBLIC HOLIDAY":
        color = "#ef9723";
        break;
      case "REMINDER":
        color = "#ef23bea6f";
        break;
      case "CONTRACT_REMINDER":
        color = "#1890ff";
        break;
      case "SCHEDULE":
        color = "#39b6e5c9";
        break;
      case "CONTRACT_SCHEDULE":
        color = "#0082e4cc";
        break;
    }
    // var backgroundColor = "#" + event.hexColor;
    var style = {
      // backgroundColor: backgroundColor,
      backgroundColor: color,
      borderRadius: "0px",
      opacity: 0.8,
      color: "black",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  let overlapCustom = (
    events,
    minimumStartDifference,
    slotMetrics,
    accessor
  ) => {
    return [];
  };

  let handleSelectSlot = (event) => {
  };

  const removeDateOrDeadLine = (str) => {
    let res = str.match(/Date or Deadline/);
    if (res) {
      return str.slice(res.index + 16);
    } else return str;
  };

  const evenContentFunc = (event) => {
    return removeDateOrDeadLine(event.event._def.title);
  };

  React.useEffect(() => {
    const el = (query) => document.querySelector(query);
    if (modalVisivle.status) {
      const modal = el(".ant-modal-content");
      const modalWrapper = el(".ant-modal");
      el(".ant-modal-header").style.borderRadius = "5px";
      modalWrapper.style.borderRadius = "5px";
      modal.style.borderRadius = "5px";
    }
  }, [modalVisivle]);

  React.useEffect(() => {
    const controlBar = d.el.q(".fc-toolbar-chunk>div");
    const backButton = d.el.q(".fc-back-button");
    if (controlBar) {
      d.el.s(controlBar, {
        display: "flex",
        gap: "20px",
      });
      d.el.sM(
        [
          controlBar.children[0],
          controlBar.children[1],
          controlBar.children[2],
        ],
        {
          backgroundColor: "white",
          color: "#1890FF",
          border: "none",
        }
      );
      // controlBar.children[0].innerHTML = `<svg height="30" viewBox="0 0 30 30" width="30"><path d="M1216 448v896q0 26-19 45t-45 19-45-19l-448-448q-19-19-19-45t19-45l448-448q19-19 45-19t45 19 19 45z"/></svg>`
      // controlBar.children[2].innerHTML = `<svg height="30" viewBox="0 0 30 30" width="30"><path transform="rotate(180deg)" d="M1216 448v896q0 26-19 45t-45 19-45-19l-448-448q-19-19-19-45t19-45l448-448q19-19 45-19t45 19 19 45z"/></svg>`
    }
    if (backButton) {
      d.el.sM([backButton], {
        backgroundColor: "white",
        color: "#1890FF",
        border: "none",
      });
    }
  });

  React.useEffect(() => {
    window.onmessage = (evt) => {
  
      if (
        evt.origin === process.env.REACT_APP_WEB_BASE_URL &&
        evt.data === "Please Send the date back"
      ) {
        setEvent(evt);
      } else console.log("not authorized", evt.origin);
    };
  }, []);

  const headerEventListeners = () => {
    const headerCell = d.el.qA('th[role="columnheader"]');
    if (headerCell && event) {
      headerCell.forEach((e, i) => {
        e.addEventListener("click", function (ev) {
          const date = e.textContent.split(" ")[1];
          const month = date.split("/")[0];
          const day = date.split("/")[1];
          event.source.postMessage(
            { message: "Date from calendar", year: selectedYear, month, day },
            event.origin
          );
        });
      });
    }
    const timeGridAxisCell = d.el.q(".fc-timegrid-axis-frame-liquid");
    const anotherElement = d.el.q(".fc-timegrid-axis-cushion");
    if (timeGridAxisCell) {
      timeGridAxisCell.innerText = "All Day";
    } else if (anotherElement) {
      anotherElement.innerText = "All Day";
    }
    const backButton = d.el.q(".fc-back-button");
    if (backButton) {
      d.el.s(backButton, {
        fontSize: "20px",
      });
    }
  };
  let homeHandle = () => {
    let redirectFlag = JSON.parse(localStorage.getItem("persistKey"));

    let redirectHome = async () => {
      await window?.ReactNativeWebView?.postMessage("WINDOW_CLOSED");
    };

    (() => {
      if (redirectFlag && redirectFlag["redirectTo"] === "webApp") {
        // window.location = apiURL;
        window.history.back();
      } else {
        redirectHome();
      }
    })();
  };
  React.useEffect(headerEventListeners);

   function isDateInRange(startDate, endDate) {
    const currentDate = new Date();
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    setdisableBtn(currentDate >= startDate && currentDate <= endDate);
    return currentDate >= startDate && currentDate <= endDate;
  }


  const handleRedirect = (ev) => {
    const modalTitle = d.el.id("modal-title-text");
    if (modalTitle) {
      const date = modalTitle.textContent.split(" ");
      event?.source.postMessage(
        {
          message: "Date from calendar",
          year: date[3],
          month: monthNames1.indexOf(date[1]) + 1,
          day: date[2],
        },
        event.origin
      );
    }
  };
  const handleTodayDate = () => {
    let today =  moment(new Date())
     let nextDay = moment(today)
     let date = `${nextDay.year()}-${nextDay.month() + 1}-${nextDay.date()}`;
     history.push(`/calendar/${date}`);
   };

  return (
    <>
      <Modal
        title={
          <Title
            id="modal-title-text"
            level={4}
            style={{
              color: "rgb(5 93 238 / 85%)",
              textAlign: "center",
              position: "relative",
              top: "10px",
            }}
          >
            {`${moment(
              modalVisivle?.data?.[0]?.event?._instance?.range?.start
                ? (modalVisivle.data[0]?.event?._instance?.range?.start)
                : 0
            ).utc().format("dddd, MMMM Do YYYY")}`}
          </Title>
        }
        visible={modalVisivle.status}
        footer={false}
        onCancel={handleCancel}
      >
        {
          modalVisivle &&
          modalVisivle.data.find((e) => {
            let el = e.event._def.extendedProps;
            return el.eventType === "EVENT";
            }) ? (
              <Title level={5} style={{ color: "rgb(11 123 203 / 85%)" }}>
                Appointments
              </Title>
            ) : null}
            {
            modalVisivle &&
            modalVisivle.data.map((e, index) => {
              let ele = e.event._def.extendedProps;
              if (ele.eventType === "EVENT") {
                // Filter out unique addresses
                let uniqueAddresses = [...new Set(ele.fullAddress1)];

                return (
                  <Collapse
                    bordered={false}
                    accordion
                    defaultActiveKey={['0']}
                    expandIcon={({ isActive }) => <CaretRightOutlined style={{fontSize:"20px",color: "rgb(11 123 203 / 85%)"}} rotate={isActive ? 90 : 0} />}
                    className="site-collapse-custom-collapse"
                  >
                    {uniqueAddresses.map((address, i) => {
                      // Filter events with matching address
                      let events = ele.eventName1.filter((eventName, j) => ele.fullAddress1[j] === address);

                      return (
                        <Panel
                          header={<div style={{fontWeight:'bold',fontSize:"18px",color: "rgb(11 123 203 / 85%)"}}>{address}</div>}
                          key={i}
                          className="site-collapse-custom-panel"
                        >
                  {events.map((eventName, j) => (
                      <div style={{
                              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                              borderRadius: "5px",
                              padding: "10px",
                              backgroundColor: ele.backgroundColor1[i],
                              color: "white",
                              marginBottom: 2,
                            }}
                            key={i}
                            onClick={handleRedirect}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "left",
                                fontSize: 14,
                              }}
                            >
                          <div>
                            <p key={j}>{`${j+1}. ${eventName}`}</p>
                            <div
                              style={{
                                display: "flex",
                                marginLeft:"30px",
                                justifyContent: "flex-start",
                                gap: "5px",
                              }}
                            >
                              <div style={{ fontSize: 12 }}>
                                {ele.start1.map((e, ind) => {
                                  if (i === ind) {
                                    return (
                                      <div>{moment(e.getTime()).format("h:mm a")}</div>
                                    );
                                  }
                                })}
                              </div>
                              <div style={{ fontSize: 12 }}>{"-"}</div>
                              <div style={{ fontSize: 12 }}>
                                {ele.end1.map((e, ind) => {
                                  if (i === ind) {
                                    return (
                                      <div>{moment(e.getTime()).format("h:mm a")}</div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                              </div>
                            </div>
                          </div> ))}
                        </Panel>
                      );
                    })}
                  </Collapse>
                );
              }
            }
          )
        }
        {modalVisivle &&
        modalVisivle.data.find((e) => {
          let el = e.event._def.extendedProps;
          return el.eventType === "BIRTHDAY";
        }) ? (
          <Title level={5} style={{ color: "rgb(11 123 203 / 85%)" }}>
            BIRTHDAY
          </Title>
        ) : null}
        {modalVisivle &&
          modalVisivle.data.map((e, index) => {
            let ele = e.event._def.extendedProps;
            if (ele.eventType === "BIRTHDAY") {
              return ele.eventName1.map((e, i) => (
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: ele.bgcolor,
                    color: "white",
                    marginBottom: 2,
                  }}
                  key={i}
                  onClick={handleRedirect}
                >
                  <div style={{ marginBottom: "5px", fontSize: 18 }}>
                    {removeDateOrDeadLine(e)}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      gap: "5px",
                    }}
                  >
                    <div style={{ fontSize: 12 }}>
                      {ele.start1.map((e, ind) => {
                        if (i === ind) {
                          return (
                            <div>{moment(e.getTime()).format("h:mm a")}</div>
                          );
                        }
                      })}
                    </div>
                    <div style={{ fontSize: 12 }}>{"-"}</div>
                    <div style={{ fontSize: 12 }}>
                      {ele.end1.map((e, ind) => {
                        if (i === ind) {
                          return (
                            <div>{moment(e.getTime()).format("h:mm a")}</div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              ));
            }
          })}
        {modalVisivle &&
        modalVisivle.data.find((e) => {
          let el = e.event._def.extendedProps;
          return el.eventType === "PUBLIC HOLIDAY";
        }) ? (
          <Title level={5} style={{ color: "rgb(11 123 203 / 85%)" }}>
            HOLIDAY
          </Title>
        ) : null}
        {modalVisivle &&
          modalVisivle.data.map((e, index) => {
            let ele = e.event._def.extendedProps;
            if (ele.eventType === "PUBLIC HOLIDAY") {
              return ele.eventName1.map((e, i) => (
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: ele.bgcolor,
                    color: "white",
                    marginBottom: 2,
                  }}
                  key={i}
                  onClick={handleRedirect}
                >
                  <div style={{ marginBottom: "5px", fontSize: 18 }}>
                    {removeDateOrDeadLine(e)}
                  </div>
                  {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        gap: "5px",
                      }}
                    >
                      <div style={{ fontSize: 12 }}>
                        {ele.start1.map((e, ind) => {
                          if (i === ind) {
                            return (
                              <div>
                                {moment(e.getTime()).format(
                                  "h:mm a"
                                )}
                              </div>
                            );
                          }
                        })}
                      </div>
                      <div style={{ fontSize: 12 }}>{"-"}</div>
                      <div style={{ fontSize: 12 }}>
                        {ele.end1.map((e, ind) => {
                          if (i === ind) {
                            return (
                              <div>
                                {moment(e.getTime()).format(
                                  "h:mm a"
                                )}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div> */}
                </div>
              ));
            }
          })}
        {modalVisivle &&
        modalVisivle.data.find((e) => {
          let el = e.event._def.extendedProps;
          return el.type === "CONTRACT_REMINDER";
        }) ? (
          <Title level={5} style={{ color: "rgb(11 123 203 / 85%)" }}>
            Contract Reminder
          </Title>
        ) : null}
        {modalVisivle &&
          modalVisivle.data.map((e, index) => {
            let ele = e.event._def.extendedProps;
            let title = e.event._def.title;
            if (ele.type === "CONTRACT_REMINDER") {
              return [ele].map((e, i) => (
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: e.bgcolor,
                    color: "white",
                    marginBottom: 2,
                  }}
                  key={i}
                  onClick={handleRedirect}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      fontSize: 14,
                    }}
                  >
                    <div>{e.fullAddress}</div>
                  </div>
                  <div
                    style={{
                      marginBottom: "5px",
                      fontWeight: "bolder",
                      fontSize: 18,
                    }}
                  >
                    {title}
                  </div>
                  <div style={{ fontSize: "12px" }}>{e.description}</div>
                </div>
              ));
            }
          })}
        {modalVisivle &&
        modalVisivle.data.find((e) => {
          let el = e.event._def.extendedProps;
          return el.type === "CONTRACT_SCHEDULE";
        }) ? (
          <Title level={5} style={{ color: "rgb(11 123 203 / 85%)" }}>
            Contract Schedule
          </Title>
        ) : null}
        {modalVisivle &&
          modalVisivle.data.map((e, index) => {
            let ele = e.event._def.extendedProps;
            let title = e.event._def.title;
            if (ele.type === "CONTRACT_SCHEDULE") {
              return [ele].map((e, i) => (
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    borderRadius: "5px",
                    padding: "10px",
                    marginBottom: 2,
                    backgroundColor: e.bgcolor,
                    color: "white",
                    marginBottom: 2,
                  }}
                  key={i}
                >
                  <div
                    style={{
                      marginBottom: "5px",
                      fontWeight: "bolder",
                      fontSize: 18,
                    }}
                  >
                    {ele.fullAddress}
                  </div>
                 <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      fontSize: 14,
                    }}
                  >
                    <div>{title}</div>
                  </div>
                  
                  {/* <div style={{ fontSize: "12px" }}>{ele.description}</div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      gap: "5px",
                    }}
                  >
                    <div style={{ fontSize: 12 }}>
                      <div>{moment((new Date(ele.startTimestamp)).getTime()).format("h:mm a")}</div>
                    </div>
                    <div style={{ fontSize: 12 }}>{"-"}</div>
                    <div style={{ fontSize: 12 }}>
                      <div>{moment((new Date(ele.endTimestamp)).getTime()).format("h:mm a")}</div>
                    </div>
                  </div>
                </div>
              ));
            }
          })}
        {modalVisivle &&
        modalVisivle.data.find((e) => {
          let ele = e.event._def.extendedProps;
          return ele.type === "SCHEDULE";
        }) ? (
          <Title level={5} style={{ color: "rgb(11 123 203 / 85%)" }}>
            SCHEDULE
          </Title>
        ) : null}
        {modalVisivle &&
          modalVisivle.data.map((e, index) => {
            let ele = e.event._def;
            if (ele?.extendedProps?.type == "SCHEDULE") {
              return <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: ele?.extendedProps?.bgcolor,
                    color: "white",
                    marginBottom: 2,
                  }}
                  key={index}
                  onClick={handleRedirect}
                >
                  <div
                    style={{
                      marginBottom: "5px",
                      fontWeight: "bolder",
                      fontSize: 18,
                    }}
                  >
                    {/* {removeDateOrDeadLine(e)} */}
                    {ele?.title}
                  </div>
                  <div style={{ fontSize: "12px" }}>
                    {/* {ele.description1.map((e, ind) => {
                      if (i === ind) {
                        return <div>{e}</div>;
                      }
                    })} */}
                    {ele?.extendedProps?.description}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      gap: "5px",
                    }}
                  >
                    <div style={{ fontSize: 12 }}>
                      {/* {ele.start1.map((e, ind) => {
                        if (i === ind) {
                          return ( */}
                            <div>{moment(e?.el?.fcSeg?.start).utc().format("h:mm a")}</div>
                          {/* );
                        }
                      })} */}
                    </div>
                    <div style={{ fontSize: 12 }}>{"-"}</div>
                    <div style={{ fontSize: 12 }}>
                      {/* {ele.end1.map((e, ind) => {
                        if (i === ind) {
                          return ( */}
                            <div>{moment(e?.el?.fcSeg?.end).utc().format("h:mm a")}</div>
                          {/* );
                        }
                      })} */}
                    </div>
                  </div>
                </div>
            }
          })}
        {modalVisivle &&
        modalVisivle.data.find((e) => {
          let el = e.event._def.extendedProps;
          return el.type === "REMINDER";
        }) ? (
          <Title level={5} style={{ color: "rgb(11 123 203 / 85%)" }}>
            REMINDER
          </Title>
        ) : null}
        {modalVisivle &&
          modalVisivle.data.map((e, index) => {
            let ele = e.event._def.extendedProps;
            let title = e.event._def.title;
            if (ele.type == "REMINDER") {
              return [ele]?.map((e, i) => (
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: e.bgcolor,
                    color: "white",
                    marginBottom: 2,
                  }}
                  key={i}
                  onClick={handleRedirect}
                >
                  <div
                    style={{
                      marginBottom: "5px",
                      fontWeight: "bolder",
                      fontSize: 18,
                    }}
                  >
                    {title}
                  </div>
                  <div style={{ fontSize: "12px" }}>{e.description}</div>
                </div>
              ));
            }
          })}
        {modalVisivle &&
        modalVisivle.data.find((e) => {
          let el = e.event._def.extendedProps;
          return el.type === "CLIENT_EVENT";
        }) ? (
          <Title level={5} style={{ color: "rgb(11 123 203 / 85%)" }}>
            REMINDER
          </Title>
        ) : null}
        {modalVisivle &&
          modalVisivle.data.map((e, index) => {
            let ele = e.event._def.extendedProps;
            let title = e.event._def.title;
            if (ele.type == "CLIENT_EVENT") {
              return [ele]?.map((e, i) => (
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: e.bgcolor,
                    color: "white",
                    marginBottom: 2,
                  }}
                  key={i}
                  onClick={handleRedirect}
                >
                  <div
                    style={{
                      marginBottom: "5px",
                      fontWeight: "bolder",
                      fontSize: 18,
                    }}
                  >
                    {title}
                  </div>
                  <div style={{ fontSize: "12px" }}>
                    {e.description ? e.description : ""}
                  </div>
                </div>
              ));
            }
          })}
      </Modal>
      <Modal
        closable={false}
        title={
          <div style={{ textAlign: "center" }}>
            <Select
              defaultValue={date.getFullYear()}
              bordered={false}
              onChange={handleSelect}
            >
              <Option value={date.getFullYear()}>{date.getFullYear()}</Option>
              <Option value={date.getFullYear() - 1}>
                {date.getFullYear() - 1}
              </Option>
              <Option value={date.getFullYear() + 1}>
                {date.getFullYear() + 1}
              </Option>
            </Select>{" "}
          </div>
        }
        visible={monthVisible}
        footer={false}
        style={{ top: "calc(100vh - 22rem)" }}
        onCancel={() => setMonthVisible(false)}
      >
        <Row gutter={[24, 24]}>
          {calendarData ? (
            getMonthDatble.map((month, i) => {
              return (
                <Col key={i} span={8} style={{ textAlign: "center" }}>
                  <Badge
                    count={
                      calendarData[month] !== undefined
                        ? calendarData[month].length
                        : 0
                    }
                  >
                    <Button
                      onClick={() => handleMonth(calendarData[month], month)}
                    >
                      {monthNames[parseInt(month) - 1]}
                    </Button>
                  </Badge>
                </Col>
              );
            })
          ) : (
            <Loading />
          )}
        </Row>
      </Modal>

      {!calendarData || calendarData.length <= 0 ? <Loading /> : (
          <div style={{padding: "0 5px", backgroundColor: "white"}}>
            {window.screen.width > 800 ? (
              <>
               {(viewType === "week") && <div style={{ position: "absolute", top: "7px", left: "65%", }}>
                  <span
                    onClick={resetToCurrentWeek}
                    style={{
                      marginLeft: "30px",
                      pointerEvents: disableBtn ? 'none' : 'auto',
                      marginTop: "-7px",
                    }}>
                    <Button
                      className={styles.resetBtn}
                      size="middle"
                      style={{
                        borderRadius: "8px",
                        marginTop: "-7px",
                        color: "white",
                        background: disableBtn ? "grey" : "#4865AA",
                        height: "40px",
                      }}
                    >
                      <span style={{ fontSize: "16px" }}>Reset to Today</span>
                    </Button>
                  </span>
                </div>}

              {
              (viewType === "month") ? <CustomCalendar events={eventsMemoizedValues} source={source} documentId={documentId} calendarDataLoading={calendarDataLoading} isInWebView={isInWebView} calendarDataEncrypt={calendarDataEncrypt} shareCalendarLoading={shareCalendarLoading} /> :
                <FullCalendar
                  plugins={[timegrid, dayGridPlugin]}
                  ref={calendarRef}
                  initialView={getInitialView()}
                  // dateClick={this.handleDateClick}
                  events={eventsMemoizedValues}
                  themeSystem="bootstrap5"
                  headerToolbar={{
                    start: "",
                    center: "prev,title,next",
                    end: "",
                  }}
                  contentHeight="80vh"
                  eventClick={handleSelected}
                  eventMaxStack={2}
                  dayMaxEventRows={2}
                  // eventBackgroundColor={(e)=>{console.log(e); return 'green'}}
                  slotLabelInterval={{ hours: 2 }}
                  scrollTime={"08:00:00"}
                  eventContent={evenContentFunc}
                  allDaySlot={true}
                  nowIndicator={true}
                  // dateClick={function(ev){console.log(ev,'date click event')}}
                  // weekends={false}
                  firstDay={1}
                  dayMinWidth="500px"
                  //   datesSet={headerEventListeners}
                  datesSet={(info) => {
                    headerEventListeners();
                    isDateInRange(info.start,info.end)
                  }}
                  handleWindowResize={true}
                />}
              </>
             ) : (
              <>
              {
                (viewType === "month") ? <CustomCalendar events={eventsMemoizedValues} source={source} documentId={documentId} calendarDataLoading={calendarDataLoading} isInWebView={isInWebView} calendarDataEncrypt={calendarDataEncrypt} shareCalendarLoading={shareCalendarLoading} /> :
                <FullCalendar
                  plugins={[timegrid, dayGridPlugin ]}
                  ref={calendarRef}
                  initialView={getInitialView()}
                  // dateClick={this.handleDateClick}
                  events={eventsMemoizedValues}
                  themeSystem="bootstrap5"
                  // customButtons={{
                  //   back: {
                  //     text: "X",
                  //     click: function () {
                  //       homeHandle();
                  //     },
                  //   },
                  // }}
                  headerToolbar={{
                    start: "back",
                    center: "prev,title,next",
                    end: "",
                  }}
                  contentHeight="80vh"
                  eventClick={handleSelected}
                  eventMaxStack={2}
                  dayMaxEventRows={2}
                  // eventBackgroundColor={(e)=>{console.log(e); return 'green'}}
                  slotLabelInterval={{ hours: 2 }}
                  scrollTime={"08:00:00"}
                  eventContent={evenContentFunc}
                  allDaySlot={true}
                  nowIndicator={true}
                  // dateClick={function(ev){console.log(ev,'date click event')}}
                  // weekends={false}
                  firstDay={1}
                  dayMinWidth="500px"
                //   datesSet={headerEventListeners}
                datesSet={(info) => {
                    headerEventListeners();
                    isDateInRange(info.start,info.end)
                    }}
                  handleWindowResize={true}
                  longPressDelay={1000}
                />
              }
              </>
            )}
          </div>
      )}
    </>
  );
}
export { CalenderComponent };
