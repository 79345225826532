/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import "./style/index.css";
import RtdDesign from "./component/RtdDesign";
import {  useDispatch, useSelector } from "react-redux";
import {  useLocation } from "react-router-dom";
import { transactionLaneDataHandle } from "./state/actions";
import { Loading } from "../Common/components/Loading";
import DoraDocument from "./component/DoraDocument";
import NavbarForRtd from "./component/NavbarForRtd";
// import styles from "./style/rtd.module.css"
import styles from "./style/TransactionLane.module.css";

const TransactionLane = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [currentlySelectedData, setCurrentlySelectedData] = useState();


  var queryParams = window.location.href
  var urlParts = queryParams.split('/');
  var lastPart = urlParts[urlParts.length - 1];
  var formattedId = lastPart.replace(/-/g, '');
  let rtdClient = null;

  const { offerTableData, offerTableAuthKey, URLofferTableJSON } = useSelector((state) => state.offerTable);
  useEffect(() => {
   let selectd =  offerTableData?.filter((el)=>el?.offerId===formattedId);
   setCurrentlySelectedData(selectd?.[0]);
 
  }, [offerTableData])

  const { getTransactionLaneData, getTransactionLaneLoading } = useSelector(
    (state) => state.rtdTransactionLane
  );


  rtdClient = "SELLER";



  const handlePrintTransRep = (item) => {
    let arr = location.pathname.split("/");
    let id = arr.pop();
    id = id.split("-").join("");
    let urlData = {
      urlData: {
        offerId: id,
        key: localStorage.getItem("userAuth"),
        redirectTo: "webApp",
        redirectBackUrl: location?.pathname || "",
      },
    };
    dispatch(transactionLaneDataHandle.getDraftDocumentData(urlData, "Print"));
  };

  useEffect(() => {
    if(offerTableAuthKey){
    dispatch(
      transactionLaneDataHandle.getTransactionLaneMethod(
        formattedId,
        "SELLER",
        offerTableAuthKey
      )
    );

    let data = {
      listType: "SELLER",
      offerId: formattedId,
      offerTableAuthKey
    };
    dispatch(transactionLaneDataHandle.getDoraDocumentsData(data));
    dispatch(transactionLaneDataHandle.getEmail_Template(offerTableAuthKey));
  }
  }, [offerTableAuthKey])



  return (
    <>
      {
        false ? (
          <Loading />
        ) : (
            <div className={styles.mainContainer}>
              <div className={styles.preRTDNav}>
                <NavbarForRtd currentlySelectedData={currentlySelectedData} />
              </div>
              {!getTransactionLaneData || getTransactionLaneLoading
                ? (
                  <Loading />
                ) : (
                  <div className={styles.outerSectionScroll}>
                    <div className={styles.preRTD}>
                      <div className={styles.RTDSection}>
                        <RtdDesign
                          tags={rtdClient}
                          client={rtdClient}
                          selectedData={currentlySelectedData}
                          userAuthKey={offerTableAuthKey}
                          URLofferTableJSON={URLofferTableJSON}
                        />
                      </div>
                      <div className={styles.DoraDocumentSection}>
                        <DoraDocument
                          selectedData={currentlySelectedData}
                          seller="SELLER"
                          userAuthKey={offerTableAuthKey}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>
        )}
    </>
  );
};

export { TransactionLane };
